import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconMail, IconLock, Icon2fa } from '@tabler/icons-react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doLogin, doLoginWith2FA } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Image, message, Modal, Row } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import undraw_questions from '@app/assets/images/undraw_questions.svg';

interface LoginFormData {
  email: string;
  password: string;
}

interface LoginFormData2FA {
  twoFactorAuthenticationCode: string;
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
};

const ShowRegisterLink = false;

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { appName } = useAppSelector((state) => state.appLayout);
  const { user } = useAppSelector((state) => state.user);

  const [isLoading, setLoading] = useState(false);
  const [show2FACode, setShow2FACode] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleHelpClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then((data) => {
        if (data.redirectTo2FA) {
          setShow2FACode(true);
          setLoading(false);
        } else {
          navigate('/');
        }
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const handle2FASubmit = (values: LoginFormData2FA) => {
    setLoading(true);
    dispatch(
      doLoginWith2FA({ userId: user?._id ?? '', twoFactorAuthenticationCode: values.twoFactorAuthenticationCode }),
    )
      .unwrap()
      .then((codeIsValid) => {
        if (codeIsValid) {
          navigate('/');
        } else {
          message.warn('The 2FA code is not valid');
        }
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      {show2FACode ? (
        <BaseForm layout="vertical" onFinish={handle2FASubmit} requiredMark="optional" initialValues={initValues}>
          <Auth.FormTitle>{t('common.loginHeader', { appName: appName })}</Auth.FormTitle>
          <S.LoginDescription>{t('login.loginInfo2FA', { appName: appName })}</S.LoginDescription>
          <Auth.FormItem
            name="twoFactorAuthenticationCode"
            label={t('login.2FAplaceholder')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput prefix={<Icon2fa color="#9A9B9F" />} />
          </Auth.FormItem>
          <a onClick={handleHelpClick} style={{ marginTop: '6px', cursor: 'pointer' }}>
            <NormalText size="s" colorType="primary">
              {t('login.2FAneedHelp')}
            </NormalText>
          </a>

          <BaseForm.Item noStyle>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.login')}
            </Auth.SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      ) : (
        <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
          <Auth.FormTitle>{t('common.loginHeader', { appName: appName })}</Auth.FormTitle>
          <S.LoginDescription>{t('login.loginInfo', { appName: appName })}</S.LoginDescription>
          <Auth.FormItem
            name="email"
            label={t('common.email')}
            rules={[
              { required: true, message: t('common.requiredField') },
              {
                type: 'email',
                message: t('common.notValidEmail'),
              },
            ]}
          >
            <Auth.FormInput prefix={<IconMail color="#9A9B9F" />} placeholder={t('common.email')} />
          </Auth.FormItem>
          <Auth.FormItem
            label={t('common.password')}
            name="password"
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInputPassword prefix={<IconLock color="#9A9B9F" />} placeholder={t('common.password')} />
          </Auth.FormItem>
          <Auth.ActionsWrapper>
            <BaseForm.Item noStyle>
              <div />
            </BaseForm.Item>
            <Link to="/auth/forgot-password">
              <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
            </Link>
          </Auth.ActionsWrapper>
          <BaseForm.Item noStyle>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.login')}
            </Auth.SubmitButton>
          </BaseForm.Item>
          {ShowRegisterLink && (
            <Auth.FooterWrapper>
              <Auth.Text>{t('login.noAccount')} </Auth.Text>
              <Link to="/auth/secure-sign-up">
                <S.ForgotPasswordText>{t('common.signUp', { appName: appName })}</S.ForgotPasswordText>
              </Link>
            </Auth.FooterWrapper>
          )}
        </BaseForm>
      )}
      <Modal open={isModalVisible} onCancel={handleModalClose} footer={null}>
        <Row align={'middle'} justify={'center'} style={{ marginTop: '1rem', marginBottom: '1rem', padding: '0 1rem' }}>
          <Image src={undraw_questions} preview={false} style={{ width: 200, height: 200 }} />
          <NormalText centered style={{ margin: '1rem 0' }}>
            {t('login.2FAneedHelpSupport')} <a href="mailto:support@co-brain.ai">support@co-brain.ai</a>.
          </NormalText>
        </Row>
      </Modal>
    </Auth.FormWrapper>
  );
};
