import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { DropdownMenu } from '@app/components/header/Header.styles';
import { ThemePicker } from '../../settingsDropdown/settingsOverlay/ThemePicker/ThemePicker';
import { Image, Modal, Row } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import undraw_questions from '@app/assets/images/undraw_questions.svg';
import { IconHelpCircle } from '@tabler/icons-react';

const showThemePicker = true;

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <DropdownMenu selectable={false} {...props}>
      <S.MenuItem key={0}>
        <S.Text>
          <Link to={'/profile'} state={{ isSettingsMenu: 'true' }}>
            {t('profile.menuTitle')}
          </Link>
        </S.Text>
      </S.MenuItem>
      <S.ItemsDivider />
      <S.MenuItem key={1}>
        <S.Text>
          <Link to="/logout">{t('header.logout')}</Link>
        </S.Text>
      </S.MenuItem>

      <S.ItemsDivider />
      {showThemePicker && (
        <S.ActionItem>
          <Row justify={'space-between'} align={'middle'}>
            <ThemePicker />
            <IconHelpCircle color="var(--primary-color)" onClick={() => setIsModalVisible(true)} />
          </Row>
        </S.ActionItem>
      )}
      <Modal open={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
        <Row align={'middle'} justify={'center'} style={{ marginTop: '1rem', marginBottom: '1rem', padding: '0 1rem' }}>
          <Image src={undraw_questions} preview={false} style={{ width: 200, height: 200 }} />
          <NormalText centered style={{ margin: '1rem 0' }}>
            {t('header.needHelpSupport')} <a href="mailto:support@co-brain.ai">support@co-brain.ai</a>.
          </NormalText>
        </Row>
      </Modal>
    </DropdownMenu>
  );
};
