import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Row, message, Space, Tooltip, Switch } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import {
  EditorContainer,
  FileUploaderContainer,
  RootContainer,
  IntialMainContainer,
  FooterContainer,
  StepsContainer,
  EditorHeaderContainer,
} from './GptDocumentChecker.styles';
import { BaseSelect } from '../../common/selects/BaseSelect/BaseSelect';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import { SingleFileUploader, LocalStoredFile } from '../../singleFileUploader/SingleFileUploader';
import {
  BEDROCK_GPT_MODEL,
  CLAUDE_V3_5_SONNET,
  enableBedrockModels,
  enableGeminiModels,
  enableO1Models,
  GEMINI_1_5_pro,
  GEMINI_GPT_MODEL,
  GPT_4o,
  GPT_MODEL,
  GPT_o1_mini,
} from '@app/utils/constants';
import { DocumentChecklist } from '@app/types/documentCheckTypes';
import { useTranslation } from 'react-i18next';
import { ChecklistSelect } from './ChecklistSelect';
import { Steps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHandleChecklists } from '@app/hooks/useHandleChecklists';
import moment from 'moment';
import { ModalCreateDocumentChecklistBasic } from './ModalCreateDocumentChecklistBasic';

const gptModelSelectOptions = [
  { label: 'GPT-4o', value: GPT_4o },
  //{ label: 'GPT-4o-mini (for simple Documents, faster)', value: GPT_4o_mini },
  { label: 'o1-mini', value: GPT_o1_mini, disabled: !enableO1Models },
  // { label: 'Claude 3 Haiku (for simple Documents, faster)', value: CLAUDE_V3_HAIKU, disabled: !enableBedrockModels },
  // { label: 'Claude 3 Sonnet ', value: CLAUDE_V3_SONNET, disabled: !enableBedrockModels },
  {
    label: 'Claude 3.5 Sonnet',
    value: CLAUDE_V3_5_SONNET,
    disabled: !enableBedrockModels,
  },
  {
    value: GEMINI_1_5_pro,
    label: 'Gemini 1.5 pro',
    disabled: !enableGeminiModels,
  },
];

const initValues = {
  outputFormat: 'Text',
  modelName: 'GPT-4o ',
  customPrompt: '',
};

const GptDocumentReviewUploader: React.FC = () => {
  const [isShowCustomPrompt] = useState<boolean>(false);
  const [filesToReview, setFilesToReview] = useState<LocalStoredFile[]>([]);
  const [selectedChecklist, setSelectedChecklist] = useState<DocumentChecklist | null>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { documentChecklists, onDeleteDocumentChecklist } = useHandleChecklists();

  const { t } = useTranslation();

  const location = useLocation();

  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  const presetChecklistId = useRef();

  useEffect(() => {
    if (location.state?.presetChecklistId) {
      presetChecklistId.current = location.state?.presetChecklistId;
    }
    if (documentChecklists.length && presetChecklistId.current) {
      const checklist = documentChecklists.find(
        (documentChecklist) => documentChecklist._id === presetChecklistId.current,
      );
      if (checklist) {
        setSelectedChecklist(checklist);
      }
    }
  }, [documentChecklists, location]);

  const onStartDocumentCheck = async () => {
    if (!filesToReview.length) {
      message.warn(t('documentChecker.uploadToCheckDocument'));
      return;
    }
    if (!selectedChecklist?._id) {
      message.warn(t('documentChecker.selectChecklist'));
      return;
    }

    const values = await form.validateFields();

    const modelName: GPT_MODEL | BEDROCK_GPT_MODEL | GEMINI_GPT_MODEL =
      (gptModelSelectOptions.find((item) => item.value === values.modelName)?.value as GPT_MODEL) ?? GPT_4o;

    const checklistId = selectedChecklist?._id;

    navigate('/zen/document-review-results', {
      state: { startNewReview: true, modelName, checklistId, filesToReview, timestampStartClicked: moment().unix() },
    });
  };

  const handleFileUpload = (file: LocalStoredFile): void => {
    setFilesToReview([...filesToReview, file]);
  };

  const handleFileRemove = (uid: string): void => {
    const filesToSave = filesToReview.filter((file) => file.uid !== uid);
    setFilesToReview(filesToSave);
  };

  const onDeleteDocumentChecklistClicked = async (documentChecklistId: string) => {
    await onDeleteDocumentChecklist(documentChecklistId);
    if (selectedChecklist?._id === documentChecklistId) {
      setSelectedChecklist(null);
    }
  };

  return (
    <RootContainer>
      <Row justify={'center'} align={'middle'}>
        <div>
          <StepsContainer>
            <Steps
              size="small"
              current={0}
              items={[
                {
                  title: 'Step 1',
                  description: 'Document Upload',
                },
                {
                  title: 'Step 2',
                  description: 'Review Analysis',
                },
              ]}
            />
          </StepsContainer>
          <Row justify={'center'} align={'middle'}>
            <IntialMainContainer>
              <FileUploaderContainer>
                <Row justify={'space-between'}>
                  <Space>
                    <NormalText size="m" semiBold verticalPadding>
                      {t('documentChecker.selectOrUploadChecklist')}
                    </NormalText>
                  </Space>
                  <Button type="link" onClick={() => setIsModalVisible(true)}>
                    + {t('documentChecker.newChecklist')}
                  </Button>
                </Row>
                <ChecklistSelect
                  documentChecklists={documentChecklists}
                  value={selectedChecklist?.name ?? ''}
                  setSelectedChecklist={(checklistId) => {
                    const checklist = documentChecklists.find(
                      (documentChecklist) => documentChecklist._id === checklistId,
                    );
                    if (checklist) {
                      setSelectedChecklist(checklist);
                    }
                  }}
                  openEditChecklistModal={(checklist: DocumentChecklist) =>
                    navigate(`/zen/edit-checklist/${checklist._id}`)
                  }
                  onDeleteDocumentChecklist={onDeleteDocumentChecklistClicked}
                />

                <div style={{ height: 20 }} />

                <NormalText size="m" semiBold verticalPadding>
                  {t('documentChecker.uploadDocument')}
                </NormalText>
                <SingleFileUploader
                  fileData={filesToReview[0]}
                  onFileUploaded={handleFileUpload}
                  onFileRemove={handleFileRemove}
                  description={t('documentChecker.uploadDocumentDescription')}
                />
              </FileUploaderContainer>
              <EditorContainer>
                <EditorHeaderContainer>
                  <Row justify={'space-between'} align="middle">
                    <NormalText size="m" semiBold>
                      {t('furtherSettings')}
                    </NormalText>
                    <Switch checked={settingsVisible} onChange={setSettingsVisible} /> {/* Add Switch */}
                  </Row>

                  {settingsVisible && ( // Conditionally render settings
                    <Form form={form} initialValues={initValues}>
                      {isShowCustomPrompt ? (
                        <Form.Item key={'customSystemPrompt'} labelCol={{ span: 24 }} name={'customSystemPrompt'}>
                          <BaseInput.TextArea />
                        </Form.Item>
                      ) : (
                        <Form.Item key={'modelName'} labelCol={{ span: 24 }} label={'Model name'} name={'modelName'}>
                          <BaseSelect
                            options={gptModelSelectOptions.map((item) => ({
                              label: item.label,
                              value: item.value,
                              disabled: item.disabled,
                            }))}
                            defaultValue={initValues.modelName}
                          />
                        </Form.Item>
                      )}
                    </Form>
                  )}
                </EditorHeaderContainer>
                <FooterContainer>
                  <Button type="primary" onClick={() => onStartDocumentCheck()} size="middle">
                    {t('documentChecker.executeCheck')}
                  </Button>
                </FooterContainer>
              </EditorContainer>
            </IntialMainContainer>
          </Row>
        </div>
      </Row>
      <ModalCreateDocumentChecklistBasic
        showDocumentChecklistModal={isModalVisible}
        onCloseModal={() => setIsModalVisible(false)}
        onDocumentChecklistCreated={(id) => navigate(`/zen/edit-checklist/${id}`)}
      />
    </RootContainer>
  );
};

export default GptDocumentReviewUploader;
