import { DiffInformation } from '@app/components/common/DiffViewer/compute-lines';
import * as diff from 'diff';

export const shortenString = (str: string, maxLength = 20) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  } else {
    return str;
  }
};

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const truncateString = (input: string, maxLength: number): string => {
  if (input.length <= maxLength) {
    return capitalizeFirstLetter(input);
  }

  const truncated = input.substr(0, maxLength);

  if (truncated.charAt(truncated.length - 1) === ' ') {
    return capitalizeFirstLetter(truncated.trim());
  }

  const lastSpaceIndex = truncated.lastIndexOf(' ');

  if (lastSpaceIndex === -1) {
    return capitalizeFirstLetter(truncated);
  } else {
    return capitalizeFirstLetter(truncated.substr(0, lastSpaceIndex).trim());
  }
};

export const convertToUpperCaseId = (input: string): string => {
  let formatted = input.toLowerCase();
  formatted = formatted.replace(/ä/g, 'a').replace(/ü/g, 'u').replace(/ö/g, 'o').replace(/ß/g, 'ss');
  formatted = formatted.replace(/\s+/g, '_');

  // Entferne alle Nicht-ASCII-Zeichen
  formatted = formatted.replace(/[^\x00-\x7F]/g, '').toUpperCase();
  return formatted;
};

export const sanitizeString = (input: string): string => {
  return input.trim().replaceAll('\t', ' ').replaceAll('\n', ' ').replace(/\s+/g, ' ');
};

export const removeSpecialCharacters = (input: string): string => {
  // Ersetzen Sie alle nicht-alphanumerischen Zeichen durch einen leeren String
  return input ? input.replace(/[^a-zA-Z0-9]/g, '') : input;
};

/**
 * prevent showing not relevant changes
 */
export const shouldDiffBlockBeShown = (
  leftValue: string | DiffInformation[],
  rightValue: string | DiffInformation[],
): boolean => {
  let showDiffBlock = true;
  if (typeof leftValue === 'string' && typeof rightValue === 'string') {
    showDiffBlock = false;
    const diffWords = diff.diffWordsWithSpace(leftValue, rightValue);
    for (const diff of diffWords) {
      if (diff.added || diff.removed) {
        if (diff.value.length > 1 && !Number.isInteger(Number.parseInt(diff.value))) {
          showDiffBlock = true;
        } else if (diff.value.includes('#')) {
          // include headers
          showDiffBlock = true;
        }
      } else {
        showDiffBlock = true;
      }
    }
  } else if (Array.isArray(leftValue) && Array.isArray(rightValue)) {
    showDiffBlock = false;
    const allDiffWords: diff.Change[] = [];
    for (let i = 0; i < rightValue.length; i++) {
      if (i < leftValue.length) {
        const lv = leftValue[i];
        const rv = rightValue[i];
        if (typeof lv.value === 'string' && typeof rv.value === 'string') {
          const diffWords = diff.diffWordsWithSpace(lv.value, rv.value);
          allDiffWords.push(...diffWords);
          for (const diff of diffWords) {
            if (diff.added || diff.removed) {
              if (diff.value.length > 1 && !Number.isInteger(Number.parseInt(diff.value))) {
                showDiffBlock = true;
              }
            } else if (diff.value.includes('#')) {
              // include headers
              showDiffBlock = true;
            }
          }
        }
      }
    }
    if (!showDiffBlock) {
      const someChangesExists = allDiffWords.some((diff) => diff.added || diff.removed);
      if (!someChangesExists) showDiffBlock = true;
    }
  }
  return showDiffBlock;
};
