import React, { useEffect, useMemo, useState } from 'react';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NavigationHeader from '../common/NavigationHeader/NavigationHeader';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { useHandleLawEntries } from '@app/hooks/useHandleLawEntries';
import {
  EmptyLawChangesSummary,
  ILawChange,
  ILawChangeDetails,
  ILawEntry,
  ILawRevisionContent,
  LawChangeDetailState,
} from '@app/types/lawTypes';
import { ProgressContainer, RootContainer } from '../gpttemplates/DocumentCheck/GptDocumentChecker.styles';
import { LoadingSpin } from '../common/LoadingSpin';
import {
  Button,
  Col,
  Divider,
  Dropdown,
  message,
  Modal,
  Progress,
  Row,
  Space,
  Switch,
  Tabs,
  TabsProps,
  Tooltip,
} from 'antd';
import {
  createTempPdfUrl,
  recreateChangesDiffSummary,
  recreateChangesSummary,
  startFullIngestLawEntry,
  startIngestLawChanges,
} from '@app/api/lawEntries.api';
import MarkdownContainer from '../common/Markdown/MarkdownContainer';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { readAllLawRevisions } from '@app/api/lawRevisionContent.api';
import {
  ChangesContainer,
  ChangesContentContainer,
  DiffViewContainer,
  LawContentWrapper,
  LawPdfContentWrapper,
  LawTextContentWrapper,
  MainContainer,
  StepsContainer,
} from './LawDetailsPage.styles';
import HistorySteps from './HistorySteps';
import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  IconDotsVertical,
  IconEdit,
  IconExclamationCircle,
  IconExternalLink,
  IconFileDiff,
  IconFileTypePdf,
  IconSparkles,
} from '@tabler/icons-react';
import LawChangeDetails from './LawChangeDetails';
import { CloseOutlined, ExclamationCircleOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import useSocketMessageHandling from '@app/hooks/useSocketMessageHandling';
import { useResponsive } from '@app/hooks/useResponsive';
import { LawReportsOverview } from './LawReportsOverview';
import { shortenString } from '@app/utils/stringHelpers';
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { RenderShowSearchPopoverProps, searchPlugin, SingleKeyword } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { useCurrentTheme } from '@app/hooks/useCurrentTheme';
import { LawRadarDefaultDateFormat } from '@app/utils/constants';
import { stringToShortSnippetsArray } from '@app/utils/utils';
import ModalEditLawChangesSummary from './ModalEditLawChangesSummary';
import DiffViewer, { DiffMethod } from '@app/components/common/DiffViewer';

const ShowDiffOnlyMajorSwitch = true;
const showDiffSummarySection = false;

export const LawDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingRevisions, setLoadingRevisions] = useState(true);
  const [initialLoadingDone, setInitialLoadingDone] = useState(false);
  const [isDiffModalVisible, setIsDiffModalVisible] = useState(false);
  const [showNotScannedYetHint, setShowNotScannedYetHint] = useState(false);
  const [recreateSummaryLoading, setRecreateSummaryLoading] = useState(false);
  const [recreateDiffSummaryLoading, setRecreateDiffSummaryLoading] = useState(false);
  const [addChangeDetailsModeActive, setAddChangeDetailsModeActive] = useState(false);
  const [pdfFileURLToShow, setPdfFileURLToShow] = useState<string | null>(null);
  const [diffAsSplitView, setDiffAsSplitView] = useState(true);
  const [diffOnlyMajorChanges, setDiffOnlyMajorChanges] = useState(false);
  const [showOldLawHint, setShowOldLawHint] = useState(false);
  const [selectedSourceIndex, setSelectedSourceIndex] = useState<number | null>(null);
  const [lawEntry, setLawEntry] = useState<ILawEntry | null>(null);
  const [lawRevisionContents, setLawRevisionContents] = useState<ILawRevisionContent[] | null>(null);
  const [selectedLawRevisionContent, setSelectedLawRevisionContent] = useState<ILawRevisionContent | null>(null);
  const [prevLawRevisionContent, setPrevLawRevisionContent] = useState<ILawRevisionContent | null>(null);
  const [selectedLawRevisionContentIndex, setSelectedLawRevisionContentIndex] = useState<number>(0);
  const [showEditSummaryModal, setShowEditSummaryModal] = useState(false);

  const navigate = useNavigate();

  const { currentTheme } = useCurrentTheme();

  const searchPluginInstance = searchPlugin();
  const { highlight, clearHighlights, ShowSearchPopover } = searchPluginInstance;

  const getTabIndexFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('tab-index') || '1';
  };

  const [activeTabKey, setActiveTabKey] = useState<string>(getTabIndexFromUrl());

  const onChange = (key: string) => {
    navigate(`${location.pathname}?tab-index=${key}`, { state: { id: selectedLawId } });
    setActiveTabKey(key);
  };

  const companyState = useAppSelector((state) => state.company);
  const currentUser = useAppSelector((state) => state.user.user);
  const theme = useAppSelector((state) => state.theme.theme);

  const { isDesktop } = useResponsive();

  const { id: selectedLawId } = useParams();
  const { handleApiError } = useErrorHandling();

  const autoRefreshStateCallback = (autoRefreshActive: boolean) => {
    setLoading(autoRefreshActive);
  };

  const { lawEntries, lawEntryLoading, onUpdateLawEntryChanges, onLawEntryWasUpdated } = useHandleLawEntries({
    specificLawId: selectedLawId,
    autoRefreshIfSomeItemIsLoading: true,
    autoRefreshStateCallback,
  });

  const { aiProgress, aiProgressDoneStepInfo, socketChanelId, resetState } = useSocketMessageHandling(
    undefined,
    currentUser?._id && selectedLawId ? `${currentUser?._id}${selectedLawId}` : undefined,
  );

  const loadRevisions = async (foundLaw: ILawEntry) => {
    try {
      const revisions = await readAllLawRevisions(companyState._id ?? '', foundLaw.lawId);
      if (revisions.length) {
        setLawRevisionContents(revisions);
        const activeRevisionIndex = revisions.findIndex((item) => item.isActive === true);
        setSelectedLawRevisionContent(activeRevisionIndex > 0 ? revisions[activeRevisionIndex] : revisions[0]);
      } else {
        if (!lawEntry?.lastScanedAt) {
          setShowNotScannedYetHint(true);
        }
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoadingRevisions(false);
    }
  };

  useEffect(() => {
    if (selectedLawId && lawEntries.length) {
      const foundLaw = lawEntries.find((item) => item._id === selectedLawId);
      setLawEntry(foundLaw || null);

      if (foundLaw) {
        const lawIsProcessing = foundLaw.scanningState === 'IN_PROGRESS';
        if (lawIsProcessing) {
          setLoading(true);
        } else {
          loadRevisions(foundLaw);
          setLoading(false);
        }

        try {
          if (foundLaw.currentVersion) {
            const yearsDiff = moment().diff(moment(foundLaw.currentVersion, LawRadarDefaultDateFormat), 'years');
            if (yearsDiff > 1) {
              if (foundLaw.createdAt) {
                const daysDiffCreated = moment().diff(moment.unix(foundLaw.createdAt), 'days');
                if (daysDiffCreated < 30) {
                  setShowOldLawHint(true);
                }
              }
            }
          }
        } catch (error) {
          // NOP
        }
      }
    }
  }, [lawEntries]);

  useEffect(() => {
    setTimeout(() => {
      if (lawEntry?.changes?.length && !initialLoadingDone) {
        let foundActiveIndex = 0;
        lawEntry.changes.forEach((change, index) => {
          if (change) {
            const foundRevision = lawRevisionContents?.find(
              (item) => item.version === change.comingIntoForceDate && item.causedByChange === change.changeTitle,
            );
            if (foundRevision?.isActive) {
              foundActiveIndex = index;
            }
          }
        });
        setSelectedLawRevisionContentIndex(foundActiveIndex);
        setInitialLoadingDone(true);
      }
    }, 500);
  }, [lawEntry?.changes, lawRevisionContents]);

  const onStartFullIngestion = async () => {
    const startScan = async () => {
      if (lawEntry?._id) {
        try {
          setLoading(true);
          resetState();
          setPdfFileURLToShow(null);
          const updatedLawEntry = await startFullIngestLawEntry(lawEntry?._id, socketChanelId?.current);
          //setLawEntry(updatedLawEntry);
          onLawEntryWasUpdated(lawEntry?._id, updatedLawEntry);
        } catch (error) {
          handleApiError(error);
        } finally {
          setLoading(false);
          setShowNotScannedYetHint(false);
        }
      }
    };
    if (lawEntry?.lastScanedAt) {
      Modal.confirm({
        title: t('lawRadar.startFullLawScan'),
        icon: <ExclamationCircleOutlined />,
        content: t('lawRadar.areYouSureStartFullLawScan'),
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
        onOk() {
          startScan();
        },
        onCancel() {
          console.log(t('lawRadar.cancel'));
        },
      });
    } else {
      await startScan();
      lawEntry && loadRevisions(lawEntry);
    }
  };

  const onStartChangeIngestion = async () => {
    const startScan = async () => {
      if (lawEntry?._id && selectedChange?._id) {
        try {
          setLoading(true);
          resetState();
          setPdfFileURLToShow(null);
          const updatedLawEntry = await startIngestLawChanges(
            lawEntry?._id,
            selectedChange?._id,
            socketChanelId?.current,
          );
          //setLawEntry(updatedLawEntry);
          onLawEntryWasUpdated(lawEntry?._id, updatedLawEntry);
        } catch (error) {
          handleApiError(error);
        } finally {
          setLoading(false);
        }
      }
    };
    Modal.confirm({
      title: t('lawRadar.rescanLawChange'),
      icon: <ExclamationCircleOutlined />,
      content: t('lawRadar.areYouSureRescanLawChange'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        startScan();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleClickOnRevision = async (lawChange: ILawChange, index: number) => {
    if (lawChange) {
      const foundRevision = lawRevisionContents?.find(
        (item) => item.version === lawChange.comingIntoForceDate && item.causedByChange === lawChange.changeTitle,
      );

      setSelectedLawRevisionContent(foundRevision || null);
      setSelectedLawRevisionContentIndex(index);
      setSelectedSourceIndex(null);
    }
  };

  const sortElementsByDateDescending = (elements: ILawChange[]) => {
    return elements.sort((a, b) => {
      const dateA = moment(a.comingIntoForceDate, 'D.M.YYYY');
      const dateB = moment(b.comingIntoForceDate, 'D.M.YYYY');
      const resolutionDateA = moment(a.resolutionDate, 'D.M.YYYY');
      const resolutionDateB = moment(b.resolutionDate, 'D.M.YYYY');

      if (!dateA.isValid() || !dateB.isValid()) {
        return 0;
      }

      const comingIntoForceComparison = dateA.unix() - dateB.unix();
      if (comingIntoForceComparison !== 0) {
        return comingIntoForceComparison;
      }

      if (!resolutionDateA.isValid() || !resolutionDateB.isValid()) {
        return 0;
      }

      return resolutionDateA.unix() - resolutionDateB.unix();
    });
  };

  const sortedChanged = useMemo(() => {
    return sortElementsByDateDescending(lawEntry?.changes || []);
  }, [lawEntry?.changes]);

  const selectedChange = useMemo(() => {
    return selectedLawRevisionContentIndex < sortedChanged.length
      ? sortedChanged[selectedLawRevisionContentIndex]
      : null;
  }, [sortedChanged, selectedLawRevisionContentIndex]);

  const onShowDiffChanges = async (foundIndex: number) => {
    if (selectedLawRevisionContent) {
      const prevChangeTitle = sortedChanged[foundIndex].changeTitle;
      const foundPrevRevision = lawRevisionContents?.find((item) => item.causedByChange === prevChangeTitle);

      if (foundPrevRevision) {
        setPrevLawRevisionContent(foundPrevRevision);
        setIsDiffModalVisible(true);
      }
    }
  };

  const handleUpdateChangeSummary = async (changeTitle: string, updatedSummary: string) => {
    if (lawEntry) {
      try {
        await onUpdateLawEntryChanges(
          lawEntry._id,
          lawEntry.changes.map((change) => {
            if (change.changeTitle === changeTitle) {
              change.changeSummary = updatedSummary;
            }
            return change;
          }),
        );
        setShowEditSummaryModal(false);
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const getItemsForDiffChanges = () => {
    type DiffSelectItems = { label: string | React.ReactNode; key: number };
    const items: DiffSelectItems[] = [];
    const considerLastItems = 5;

    if (selectedLawRevisionContent) {
      let foundIndex = 0;
      sortedChanged.forEach((item, index) => {
        if (item.changeTitle === selectedLawRevisionContent.causedByChange) {
          foundIndex = Math.max(0, index - 1);
        }
      });
      for (let index = foundIndex; index >= 0; --index) {
        if (items.length < considerLastItems) {
          const isFirst = items.length === 0;
          items.push({
            label: (
              <NormalText>
                Compare with{' '}
                <strong>{` ${isFirst ? '(previous) ' : ''}${sortedChanged[index].comingIntoForceDate} - ${sortedChanged[index].changeTitle}`}</strong>
              </NormalText>
            ),
            key: index,
          });
        }
      }
    }
    return items;
  };

  const getRevisionIsActiveFlag = (lawChange: ILawChange) => {
    if (lawChange) {
      const foundRevision = lawRevisionContents?.find(
        (item) => item.version === lawChange.comingIntoForceDate && item.causedByChange === lawChange.changeTitle,
      );
      return foundRevision?.isActive || false;
    }
    return false;
  };

  const isLoading = lawEntryLoading || loading;

  const handleDetailsChange = async (
    index: number,
    parentChapter: string,
    nameOfArticle: string,
    heading: string,
    changeText: string,
    state: LawChangeDetailState,
  ) => {
    if (lawEntry?._id && selectedChange?.changeDetails && selectedChange.changeDetails[index]) {
      const updatedChange = {
        parentChapter,
        nameOfArticle,
        heading,
        changeText,
        state,
        editedAt: moment().unix(),
        editedBy: currentUser?._id,
      };
      selectedChange.changeDetails[index] = updatedChange;

      try {
        await onUpdateLawEntryChanges(
          lawEntry._id,
          lawEntry.changes.map((change) => {
            if (
              change.changeTitle === selectedChange.changeTitle &&
              change.comingIntoForceDate === selectedChange.comingIntoForceDate
            ) {
              change.changeDetails?.forEach((detail, i) => {
                if (i === index) {
                  detail = updatedChange;
                }
              });
            }
            return change;
          }),
        );
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const handleAddManaulyChange = async (
    parentChapter: string,
    nameOfArticle: string,
    heading: string,
    changeText: string,
  ) => {
    if (lawEntry?._id && selectedChange?.changeDetails) {
      const createdChange = {
        parentChapter,
        nameOfArticle,
        heading,
        changeText,
        state: 'INITIAL',
      } as ILawChangeDetails;

      try {
        await onUpdateLawEntryChanges(
          lawEntry._id,
          lawEntry.changes.map((change) => {
            if (
              change.changeTitle === selectedChange.changeTitle &&
              change.comingIntoForceDate === selectedChange.comingIntoForceDate
            ) {
              change.changeDetails?.push(createdChange);
            }
            return change;
          }),
        );
        setAddChangeDetailsModeActive(false);
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const onDeleteChangeDetails = (index: number) => {
    if (lawEntry?._id && selectedChange?.changeDetails && selectedChange.changeDetails[index]) {
      const deleteChangeDetails = async () => {
        selectedChange.changeDetails = selectedChange.changeDetails?.filter((_, i) => i !== index);
        try {
          await onUpdateLawEntryChanges(
            lawEntry._id,
            lawEntry.changes.map((change) => {
              if (
                change.changeTitle === selectedChange.changeTitle &&
                change.comingIntoForceDate === selectedChange.comingIntoForceDate &&
                change.changeDetails
              ) {
                change.changeDetails = change.changeDetails?.filter((_, i) => i !== index);
              }
              return change;
            }),
          );
        } catch (error) {
          handleApiError(error);
        }
      };
      Modal.confirm({
        title: t('lawRadar.deleteLawChange'),
        icon: <ExclamationCircleOutlined />,
        content: t('lawRadar.areYouSureToDeleteLawChange'),
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
        onOk() {
          deleteChangeDetails();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  };

  const onRecreateSummary = async () => {
    if (!recreateSummaryLoading && lawEntry?._id && selectedChange?.changeTitle) {
      const recreate = async () => {
        try {
          setRecreateSummaryLoading(true);
          const updatedLawEntry = await recreateChangesSummary(lawEntry._id, selectedChange?.changeTitle);
          //setLawEntry(updatedLawEntry);
          onLawEntryWasUpdated(lawEntry?._id, updatedLawEntry);
        } catch (error) {
          handleApiError(error);
        } finally {
          setRecreateSummaryLoading(false);
        }
      };
      Modal.confirm({
        title: t('lawRadar.rewriteByAI'),
        icon: <IconSparkles />,
        content: t('lawRadar.areYouSureToRecreateSummary'),
        okText: t('yes'),
        cancelText: t('no'),
        onOk() {
          recreate();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  };

  const onRecreateDiffSummary = async () => {
    if (!recreateDiffSummaryLoading && lawEntry?._id && selectedChange?.changeTitle) {
      try {
        setRecreateDiffSummaryLoading(true);
        const updatedLawEntry = await recreateChangesDiffSummary(lawEntry._id, selectedChange?.changeTitle);
        //setLawEntry(updatedLawEntry);
        onLawEntryWasUpdated(lawEntry?._id, updatedLawEntry);
      } catch (error) {
        handleApiError(error);
      } finally {
        setRecreateDiffSummaryLoading(false);
      }
    }
  };

  const onShowPdfViewer = async (pdfType: 'lawtext' | 'changetext') => {
    try {
      if (pdfType === 'changetext') {
        if (!selectedChange?.pdfUrl) {
          message.warn(t('lawRadar.pdfUrlIsNotSet'));
          return;
        }
        // to overcome CORS limitation
        const tempUrl = await createTempPdfUrl(selectedChange.pdfUrl);
        setPdfFileURLToShow(tempUrl);
      } else if (pdfType === 'lawtext') {
        if (!selectedChange?.revisionContentPDFUrl) {
          message.warn(t('lawRadar.pdfUrlIsNotSet'));
          return;
        }
        // to overcome CORS limitation
        const tempUrl = await createTempPdfUrl(selectedChange.revisionContentPDFUrl);
        setPdfFileURLToShow(tempUrl);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const onShowPdfSourceClick = async (change: ILawChangeDetails, index: number) => {
    try {
      if (selectedChange?.pdfUrl) {
        const loadPdfFile = !pdfFileURLToShow;
        if (loadPdfFile) {
          // to overcome CORS limitation
          const tempUrl = await createTempPdfUrl(selectedChange.pdfUrl);
          setPdfFileURLToShow(tempUrl);
        }

        clearHighlights();

        const highlightData: SingleKeyword | RegExp[] = [];
        const sources: string[] = [];

        let startedWithHeaders = false;

        if (change.heading && change.nameOfArticle) {
          sources.push(change.heading);
          sources.push(change.nameOfArticle);
          startedWithHeaders = true;
        } else {
          sources.push(change.changeText);
        }

        try {
          for (const source of sources) {
            const regexString = source.replace(/\s+/g, '\\s*');
            const regex = new RegExp(regexString, 'g');
            highlightData.push(regex);
          }
        } catch (error) {
          message.info('Die Änderung konnte nicht im Dokument markiert werden.');
          return;
        }

        setTimeout(
          async () => {
            let highlightSuccessfull = false;
            try {
              const matchRes = await highlight(highlightData);
              if (matchRes.length === 0) {
                try {
                  const highlightDataByLines: SingleKeyword[] = [];

                  const lines = stringToShortSnippetsArray(change.changeText);
                  lines.forEach((keyword) => {
                    if (keyword.length > 1) {
                      const regexString = keyword.replace(/\s+/g, '\\s*');
                      const regex = new RegExp(regexString, 'g');
                      highlightDataByLines.push(regex);
                    }
                  });
                  const matchRes2 = await highlight(highlightDataByLines);
                  highlightSuccessfull = matchRes2.length > 0;
                } catch (error) {
                  message.info('Die Änderung konnte nicht im Dokument markiert werden.');
                }

                if (!highlightSuccessfull) {
                  // third try
                  if (startedWithHeaders) {
                    sources.push(change.changeText);
                  }
                  try {
                    for (const source of sources) {
                      const regexString = source.replace(/\s+/g, '\\s*');
                      const regex = new RegExp(regexString, 'g');
                      highlightData.push(regex);
                    }
                  } catch (error) {
                    message.info('Die Änderung konnte nicht im Dokument markiert werden.');
                    return;
                  }

                  try {
                    const matchRes = await highlight(highlightData);
                    if (matchRes.length === 0) {
                      const highlightDataByLines: SingleKeyword[] = [];

                      for (const content of sources) {
                        const lines = stringToShortSnippetsArray(content);
                        lines.forEach((keyword) => {
                          if (keyword.length > 1) {
                            const regexString = keyword.replace(/\s+/g, '\\s*');
                            const regex = new RegExp(regexString, 'g');
                            highlightDataByLines.push(regex);
                          }
                        });
                      }
                      const matchRes3 = await highlight(highlightDataByLines);
                      highlightSuccessfull = matchRes3.length > 0;
                    } else {
                      highlightSuccessfull = true;
                    }
                  } catch (error) {
                    message.info('Die Änderung konnte nicht im Dokument markiert werden.');
                  }
                }
              } else {
                highlightSuccessfull = true;
              }
            } catch (error) {
              message.info('Die Änderung konnte nicht im Dokument markiert werden.');
            }

            if (highlightSuccessfull) {
              setSelectedSourceIndex(index);
            }
          },
          loadPdfFile ? 2000 : 1000,
        );
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const revisionContentToShow = useMemo(() => {
    if (selectedLawRevisionContent && selectedLawRevisionContent.content?.length > 0) {
      return selectedLawRevisionContent.content;
    } else if (showNotScannedYetHint) {
      return '';
    }
    return '## No revision content';
  }, [selectedLawRevisionContent, showNotScannedYetHint]);

  const noChangeDetailsAvailable = selectedChange?.changeDetails?.length === 0;

  const pdfMenuItems =
    selectedChange?.pdfUrl && selectedChange.pdfUrl.length > 0
      ? [
          { label: t('lawRadar.showLawPDF'), key: 'lawtext', icon: <IconFileTypePdf size={16} /> },
          { label: t('lawRadar.showChangePDF'), key: 'changetext', icon: <IconFileTypePdf size={16} /> },
        ]
      : [{ label: t('lawRadar.showLawPDF'), key: 'lawtext', icon: <IconFileTypePdf size={16} /> }];

  const editSummaryMenuItems = [
    { label: t('lawRadar.rewriteByAI'), key: 'rewrite', icon: <IconSparkles stroke={1} size={16} /> },
    { label: t('lawRadar.edit'), key: 'edit', icon: <IconEdit size={16} /> },
  ];

  const tabPages: TabsProps['items'] = [
    {
      key: '1',
      label: t('lawRadar.analysis'),
      children: (
        <MainContainer>
          {isDiffModalVisible ? (
            <DiffViewContainer>
              <DiffViewer
                styles={{
                  diffContainer: { width: '99vw' },
                  contentText: { fontSize: '0.75rem' },
                  lineNumber: { fontSize: '0.75rem' },
                }}
                useDarkTheme={theme === 'dark'}
                hideLineNumbers={true}
                showOnlyMajorChanges={diffOnlyMajorChanges}
                showDiffOnly={true}
                leftTitle={`${prevLawRevisionContent?.causedByChange} - ${prevLawRevisionContent?.version}`}
                rightTitle={`${selectedLawRevisionContent?.causedByChange} - ${selectedLawRevisionContent?.version}`}
                oldValue={prevLawRevisionContent?.content.replace(/\^\d+\^/g, '')}
                newValue={selectedLawRevisionContent?.content.replace(/\^\d+\^/g, '')}
                codeFoldMessageRenderer={(number) => (
                  <NormalText colorType="primary" size="s" semiBold>
                    Expand {number} of lines ...
                  </NormalText>
                )}
                splitView={diffAsSplitView}
                compareMethod={DiffMethod.WORDS}
              />
            </DiffViewContainer>
          ) : (
            <Row>
              <Col span={14}>
                {pdfFileURLToShow ? (
                  <LawPdfContentWrapper>
                    <Button
                      size="small"
                      style={{ margin: '2px 12px', height: '2rem', lineHeight: '1rem' }}
                      onClick={() => {
                        setPdfFileURLToShow(null);
                        setSelectedSourceIndex(null);
                      }}
                      icon={<CloseOutlined />}
                    ></Button>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={pdfFileURLToShow}
                        plugins={[searchPluginInstance]}
                        enableSmoothScroll
                        defaultScale={SpecialZoomLevel.PageWidth}
                      />
                    </Worker>
                    <div style={{ margin: '1rem', position: 'absolute', left: 8, top: 30 }}>
                      <div style={{ borderRadius: 100, backgroundColor: currentTheme.primaryLight }}>
                        <ShowSearchPopover>
                          {(props: RenderShowSearchPopoverProps) => (
                            <Button
                              type="text"
                              size="small"
                              onClick={props.onClick}
                              icon={<SearchOutlined style={{ color: currentTheme.primary }} />}
                            ></Button>
                          )}
                        </ShowSearchPopover>
                      </div>
                    </div>
                  </LawPdfContentWrapper>
                ) : (
                  <LawContentWrapper>
                    {sortedChanged.length > 0 && (
                      <StepsContainer>
                        <HistorySteps
                          steps={sortedChanged.map((item, index) => ({
                            title: item.changeTitle,
                            date: item.comingIntoForceDate,
                            isSelected: index === selectedLawRevisionContentIndex,
                            isActive: getRevisionIsActiveFlag(item),
                            onClick: () => handleClickOnRevision(item, index),
                          }))}
                        />
                      </StepsContainer>
                    )}
                    <LawTextContentWrapper showBorder={theme === 'light'}>
                      {loadingRevisions ? (
                        <SkeletonTheme
                          baseColor="var(--skeleton-background-color)"
                          highlightColor="var(--secondary-background-selected-color)"
                        >
                          <Skeleton count={5} style={{ height: 22, width: '100%' }} />
                        </SkeletonTheme>
                      ) : (
                        <MarkdownContainer
                          text={revisionContentToShow}
                          isTextLoading={false}
                          forceFonSize="m"
                        ></MarkdownContainer>
                      )}
                      {showNotScannedYetHint && (
                        <div style={{ textAlign: 'center', marginTop: '25%' }}>
                          <NormalText size="l" light bottomMargin={showOldLawHint ? 's' : 'm'} colorType="light">
                            {t('lawRadar.notScannedHint')}
                          </NormalText>
                          {showOldLawHint && (
                            <NormalText
                              light
                              bottomMargin="m"
                              colorType="light"
                              style={{ color: 'var(--warning-color)' }}
                            >
                              {t('lawRadar.olderLawHint', { date: lawEntry?.currentVersion ?? '-' })}
                            </NormalText>
                          )}
                          <Button loading={isLoading} type="primary" onClick={() => onStartFullIngestion()}>
                            Start Full Scan
                          </Button>
                        </div>
                      )}
                    </LawTextContentWrapper>
                  </LawContentWrapper>
                )}
              </Col>
              <Col span={10}>
                <ChangesContainer>
                  {selectedChange && (
                    <ChangesContentContainer>
                      <Row justify={'space-between'}>
                        <Col>
                          <Space>
                            <NormalText semiBold size="l">
                              {selectedChange.changeTitle}
                            </NormalText>
                            {selectedChange.scanningError && (
                              <Tooltip title={`Last scan error: ${shortenString(selectedChange.scanningError, 200)}`}>
                                <IconExclamationCircle size={18} style={{ color: 'var(--error-color)' }} />
                              </Tooltip>
                            )}
                            <Space size={'small'} style={{ marginLeft: 8 }}>
                              <a target="_blank" href={selectedChange.url} rel="noreferrer" style={{ marginRight: 4 }}>
                                <IconExternalLink size={18} />
                              </a>
                              <Dropdown
                                menu={{
                                  items: pdfMenuItems,
                                  onClick: ({ key }) => {
                                    onShowPdfViewer(key as 'lawtext' | 'changetext');
                                  },
                                }}
                                trigger={['click']}
                              >
                                <div style={{ cursor: 'pointer' }}>
                                  <IconFileTypePdf size={16} color={currentTheme.primary} />
                                </div>
                              </Dropdown>
                            </Space>
                          </Space>
                        </Col>
                        <Col>
                          <Space>
                            <Tooltip title={'Show diff viewer'}>
                              <Dropdown
                                menu={{
                                  items: getItemsForDiffChanges().map((item) => {
                                    return { label: item.label, key: item.key };
                                  }),
                                  onClick: ({ key }) => {
                                    onShowDiffChanges(parseInt(key));
                                  },
                                  style: {
                                    backgroundColor:
                                      theme === 'dark'
                                        ? 'var(--secondary-background-color)'
                                        : 'var(--background-color)',
                                  },
                                }}
                                trigger={['click']}
                                placement="bottomRight"
                              >
                                <Button type="link" size="small">
                                  <IconFileDiff size={20} style={{ marginTop: 2 }} />
                                </Button>
                              </Dropdown>
                            </Tooltip>
                            <Tooltip
                              title={`Rescan the changes page "${selectedChange.changeTitle}" and create a summary`}
                            >
                              <Button type="link" size="small" onClick={() => onStartChangeIngestion()}>
                                {t('lawRadar.startOnlyChangeScan')}
                              </Button>
                            </Tooltip>
                          </Space>
                        </Col>
                      </Row>
                      <Divider style={{ margin: '1rem 0 0.5rem 0' }} />
                      <Row justify={'space-between'}>
                        <NormalText verticalPadding semiBold size="l" colorType="primary">
                          {t('lawRadar.summary')}
                        </NormalText>
                        <Dropdown
                          menu={{
                            items: editSummaryMenuItems,
                            onClick: ({ key }) => {
                              if (key === 'rewrite') onRecreateSummary();
                              if (key === 'edit') setShowEditSummaryModal(true);
                            },
                          }}
                          trigger={['click']}
                        >
                          <div style={{ cursor: 'pointer' }}>
                            <IconDotsVertical size={18} color={currentTheme.primary} />
                          </div>
                        </Dropdown>
                      </Row>

                      {recreateSummaryLoading ? (
                        <SkeletonTheme
                          baseColor="var(--skeleton-background-color)"
                          highlightColor="var(--secondary-background-selected-color)"
                        >
                          <Skeleton count={3} style={{ height: 22, width: '100%' }} />
                        </SkeletonTheme>
                      ) : (
                        <>
                          {selectedChange.changeSummary ? (
                            <MarkdownContainer
                              text={
                                selectedChange.changeSummary === EmptyLawChangesSummary
                                  ? 'Keine Änderungen gefunden'
                                  : selectedChange.changeSummary
                              }
                              isTextLoading={false}
                              forceFonSize="s"
                            ></MarkdownContainer>
                          ) : (
                            <NormalText size="s">{t('lawRadar.noSummaryCreated')}</NormalText>
                          )}
                        </>
                      )}

                      {showDiffSummarySection && (
                        <>
                          <Row justify={'space-between'} style={{ marginTop: '1rem' }}>
                            <Space>
                              <NormalText verticalPadding semiBold size="l" colorType="primary">
                                {t('lawRadar.diffSummary')}
                              </NormalText>
                              <Tooltip title={t('lawRadar.diffSummaryToolTip')}>
                                <InfoCircleOutlined style={{ fontSize: '1rem', color: 'var(--text-light-color)' }} />
                              </Tooltip>
                            </Space>
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    label: t('lawRadar.recompareByAI'),
                                    key: 'recompare',
                                    icon: <IconSparkles stroke={1} size={16} />,
                                  },
                                ],
                                onClick: ({ key }) => {
                                  if (key === 'recompare') onRecreateDiffSummary();
                                },
                              }}
                              trigger={['click']}
                            >
                              <div style={{ cursor: 'pointer' }}>
                                <IconDotsVertical size={18} color={currentTheme.primary} />
                              </div>
                            </Dropdown>
                          </Row>
                          {recreateDiffSummaryLoading ? (
                            <SkeletonTheme
                              baseColor="var(--skeleton-background-color)"
                              highlightColor="var(--secondary-background-selected-color)"
                            >
                              <Skeleton count={3} style={{ height: 22, width: '100%' }} />
                            </SkeletonTheme>
                          ) : (
                            <>
                              {selectedChange.diffChangeSummary ? (
                                <MarkdownContainer
                                  text={
                                    selectedChange.diffChangeSummary === EmptyLawChangesSummary
                                      ? 'Keine Änderungen gefunden'
                                      : selectedChange.diffChangeSummary
                                  }
                                  isTextLoading={false}
                                  forceFonSize="s"
                                ></MarkdownContainer>
                              ) : (
                                <NormalText size="s">{t('lawRadar.noDiffSummaryCreated')}</NormalText>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {selectedChange.changeDetails && selectedChange.changeDetails.length > 0 && (
                        <>
                          <NormalText semiBold size="l" style={{ marginTop: '1.5rem' }}>
                            {t('lawRadar.allChanges')}
                          </NormalText>
                          {selectedChange.changeDetails.map((change, index) => (
                            <LawChangeDetails
                              key={`${index}_${change.nameOfArticle}`}
                              changeText={change.changeText}
                              heading={change.heading}
                              index={index + 1}
                              parentChapter={change.parentChapter}
                              nameOfArticle={change.nameOfArticle}
                              editedAt={change.editedAt}
                              editedBy={change.editedBy}
                              state={change.state || 'INITIAL'}
                              isAddMode={false}
                              onChangeDetailsChanged={(parentChapter, nameOfArticle, heading, changeText, state) =>
                                handleDetailsChange(index, parentChapter, nameOfArticle, heading, changeText, state)
                              }
                              onDeleteChangeDetails={() => onDeleteChangeDetails(index)}
                              onShowPdfSourceClick={() => onShowPdfSourceClick(change, index)}
                              showSourceButton={Boolean(selectedChange.pdfUrl && selectedChange.pdfUrl.length > 0)}
                              isSelectedSource={selectedSourceIndex !== null && selectedSourceIndex === index}
                            />
                          ))}
                        </>
                      )}
                      {!addChangeDetailsModeActive && (
                        <div
                          style={{
                            textAlign: 'center',
                            marginBottom: '1rem',
                            marginTop: noChangeDetailsAvailable ? '4rem' : '0.5rem',
                          }}
                        >
                          <Button size="small" onClick={() => setAddChangeDetailsModeActive(true)}>
                            {t('lawRadar.addChange')}
                          </Button>
                        </div>
                      )}
                      {addChangeDetailsModeActive && (
                        <LawChangeDetails
                          key={`add_change_details_1`}
                          changeText={''}
                          heading={''}
                          index={0}
                          nameOfArticle={''}
                          isAddMode={true}
                          showSourceButton={false}
                          isSelectedSource={false}
                          state={'INITIAL'}
                          onChangeDetailsChanged={(parentChapter, nameOfArticle, heading, changeText) =>
                            handleAddManaulyChange(parentChapter, nameOfArticle, heading, changeText)
                          }
                          onDeleteChangeDetails={() => {
                            /**/
                          }}
                          onCancelClick={() => setAddChangeDetailsModeActive(false)}
                        />
                      )}
                    </ChangesContentContainer>
                  )}
                </ChangesContainer>
              </Col>
            </Row>
          )}
        </MainContainer>
      ),
    },
    {
      key: '2',
      label: t('lawRadar.reports'),
      children: <LawReportsOverview lawEntry={lawEntry} />,
    },
  ];

  const titleLength = (lawEntry?.title?.length || 0) + (lawEntry?.lawId?.length || 0);

  return (
    <>
      <NavigationHeader
        title={t('lawRadar.lawDetailsHeader')}
        thinBorder
        description={
          <Space>
            {titleLength > 80 ? (
              <Tooltip title={lawEntry?.title}>
                <NormalText size={'s'} semiBold colorType={theme === 'dark' ? undefined : 'primary'}>
                  {shortenString(lawEntry?.title ?? '', 80)} - {lawEntry?.lawId}
                </NormalText>
              </Tooltip>
            ) : (
              <NormalText size={'s'} semiBold colorType={theme === 'dark' ? undefined : 'primary'}>
                {lawEntry?.title} - {lawEntry?.lawId}
              </NormalText>
            )}

            <a target="_blank" href={lawEntry?.url} rel="noreferrer">
              <IconExternalLink size={14} />
            </a>
          </Space>
        }
        onGoBack={() => navigate('/law-radar')}
        rightComponent={
          isDiffModalVisible ? (
            <div>
              <Row justify={'space-between'} align={'middle'}>
                {isDesktop && (
                  <Col>
                    <Space style={{ padding: '0 1rem' }}>
                      <div>
                        <NormalText size="xs" centered semiBold>
                          {prevLawRevisionContent?.causedByChange}
                        </NormalText>
                        <NormalText size="xs" centered>
                          {prevLawRevisionContent?.version}
                        </NormalText>
                      </div>
                      <div>
                        <NormalText size="xs" semiBold centered>
                          VS.
                        </NormalText>
                      </div>
                      <div>
                        <NormalText size="xs" centered semiBold>
                          {selectedLawRevisionContent?.causedByChange}
                        </NormalText>
                        <NormalText size="xs" centered>
                          {selectedLawRevisionContent?.version}
                        </NormalText>
                      </div>
                    </Space>
                  </Col>
                )}
                <Col style={{ padding: '0 1rem' }}>
                  <Button key="back" onClick={() => setIsDiffModalVisible(false)}>
                    {t('common.close')}
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            <>
              {activeTabKey === '1' && (
                <div style={{ textAlign: 'center' }}>
                  <Button loading={isLoading} type="primary" size="small" onClick={() => onStartFullIngestion()}>
                    {t('lawRadar.startFullScan')}
                  </Button>
                  {lawEntry?.lastScanedAt && (
                    <NormalText size="xs">
                      {t('lawRadar.lastScan', { time: moment.unix(lawEntry?.lastScanedAt).fromNow() })}
                    </NormalText>
                  )}
                </div>
              )}
            </>
          )
        }
      />
      {isLoading ? (
        <RootContainer>
          <LoadingSpin />
          {loading && (
            <ProgressContainer>
              <Progress percent={aiProgress || 0} style={{ width: 250, marginTop: '1rem', color: 'gray' }} />
              <NormalText light colorType="light">
                {aiProgressDoneStepInfo}
              </NormalText>
            </ProgressContainer>
          )}
        </RootContainer>
      ) : (
        <Tabs
          style={{ height: '35px', margin: 0 }}
          centered
          tabBarStyle={{ height: '35px', padding: '0 2rem', backgroundColor: 'var(--background-color)', margin: 0 }}
          defaultActiveKey="1"
          items={tabPages}
          activeKey={activeTabKey}
          onChange={onChange}
          tabBarExtraContent={
            isDiffModalVisible && (
              <Space>
                {ShowDiffOnlyMajorSwitch && (
                  <Space>
                    <NormalText size="s">Only major changes</NormalText>
                    <Switch
                      checked={diffOnlyMajorChanges}
                      onChange={() => setDiffOnlyMajorChanges(!diffOnlyMajorChanges)}
                    />
                  </Space>
                )}

                {ShowDiffOnlyMajorSwitch && <span>|</span>}
                <Space>
                  <NormalText size="s">Split View</NormalText>
                  <Switch checked={diffAsSplitView} onChange={() => setDiffAsSplitView(!diffAsSplitView)} />
                </Space>
              </Space>
            )
          }
        />
      )}
      <ModalEditLawChangesSummary
        visible={showEditSummaryModal}
        lawSummary={selectedChange ? selectedChange.changeSummary ?? '' : ''}
        changeTitle={selectedChange?.changeTitle ?? ''}
        onClose={() => setShowEditSummaryModal(false)}
        onUpdateSummary={handleUpdateChangeSummary}
      />
    </>
  );
};
