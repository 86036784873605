import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { doVerifyAuth } from '@app/store/slices/authSlice';
import { loadCompany } from '@app/store/slices/companySlice';
import { loadDepartments } from '@app/store/slices/departmentSlice';
import { LoadingSpin } from '../common/LoadingSpin';
import { loadKnowledgeAreas } from '@app/store/slices/knowledgeAreaSlice';
import { useTranslation } from 'react-i18next';
import { checkVersionIsOk } from '@app/api/general.api';
import { Button, Modal, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { loadChatHistory, setIntialAppDataLoaded } from '@app/store/slices/chatBoxSlice';
import { loadUserRights } from '@app/store/slices/userSlice';
import { loadSuperDoAreas } from '@app/store/slices/superDoAreaSlice';
import { loadNestedMenuStructure } from '@app/store/slices/menuFolderSlice';
import { setTriggerEventId } from '@app/store/slices/socketAppEventSlice';
import { debounce } from 'lodash';
import { ChatHistoryItemsPerPage } from '@app/hooks/useHandleChatHistory';
import InitalPageContainer from '../layouts/main/InitalPageContainer/InitalPageContainer';
import { ModalEnable2FA } from '../common/ModalEnable2FA';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [userExists, setUserExists] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user, isAdmin, sessionExpired } = useAppSelector((state) => state.user);
  const companyId = useAppSelector((state) => state.company._id);
  const { intialAppDataLoaded } = useAppSelector((state) => state.chatBox);
  const [show2faActivateModal, setShow2faActivateModal] = useState(false);

  useEffect(() => {
    dispatch(doVerifyAuth())
      .unwrap()
      .then(async (res) => {
        setUserExists(res);
        if (res) {
          debouncedLoadAllData();
        } else {
          dispatch(setIntialAppDataLoaded({ value: true }));
        }
      })
      .finally(() => setLoading(false));

    return () => {
      dispatch(setTriggerEventId({ value: 'APPLICATION_CLOSED_EVENT_ID' }));
    };
  }, [dispatch]);

  useEffect(() => {
    const checkClientVersion = async () => {
      const result = await checkVersionIsOk();
      if (!result.versionOk) {
        Modal.confirm({
          title: t('yourAppVersionIsOld'),
          icon: <InfoCircleOutlined />,
          content: t('yourAppVersionIsOldDescription'),
          okText: t('ok'),
        });
      }
    };
    if (!loading) {
      checkClientVersion();
    }
  }, [loading]);

  const loadAllData = async () => {
    try {
      await dispatch(loadCompany());
      await dispatch(loadDepartments());
      await dispatch(loadUserRights());
      await dispatch(loadNestedMenuStructure());
      await dispatch(loadKnowledgeAreas());
      await dispatch(loadChatHistory(1, ChatHistoryItemsPerPage));
      await dispatch(loadSuperDoAreas());
      if (process.env.REACT_APP_FORCE_2FA === 'true' && user && !user.isTwoFactorAuthenticationEnabled) {
        setShow2faActivateModal(true);
      }
    } finally {
      await dispatch(setIntialAppDataLoaded({ value: true }));
    }
  };

  const debouncedLoadAllData = debounce(loadAllData, 100);

  if (loading || !intialAppDataLoaded)
    return (
      <InitalPageContainer>
        <LoadingSpin marginTop={'0'} text={t('establishingSecureConnection')} />
      </InitalPageContainer>
    );
  if (user?._id && !loading && !isAdmin && !companyId)
    return (
      <NormalText size="m" centered verticalPadding colorType="light">
        {t('wrongConfigurationFound')}
      </NormalText>
    );

  return userExists ? (
    <>
      {children}
      <Modal
        title={t('yourSessionExpired')}
        closable={false}
        open={sessionExpired}
        footer={[
          <Button key="submit" type="primary" onClick={() => navigate('/logout')}>
            Ok
          </Button>,
        ]}
      >
        <Space size={'middle'} align="start">
          <InfoCircleOutlined />
          <NormalText size="m" style={{ marginBottom: '1rem' }}>
            {t('yourSessionExpiredHint')}
          </NormalText>
        </Space>
      </Modal>
      <ModalEnable2FA
        showIntroScreen={true}
        visible={show2faActivateModal}
        onClose={() => setShow2faActivateModal(false)}
      />
    </>
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

export default RequireAuth;
