import React, { useEffect } from 'react';
import { FormInstance } from 'antd';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import { EmptyInfoText } from './GptTemplatesDetailInputControlls.styles';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '../common/selects/BaseSelect/BaseSelect';
import { SuperDoTemplateInputInfo } from './templateDataInputMapping';
import { LocalStoredFile, SingleFileUploader } from '../singleFileUploader/SingleFileUploader';
import { SupportedFileTypesForUploader } from '@app/utils/constants';

type Props = {
  superDoInputs: SuperDoTemplateInputInfo[];
  form: FormInstance;
  handleFileUpload: (file: LocalStoredFile, inputId: string) => void;
  handleFileRemove: (uid: string) => void;
  uploadedFiles: LocalStoredFile[];
};

const GptTemplatesDetailInputControlls: React.FC<Props> = ({
  superDoInputs,
  form,
  uploadedFiles,
  handleFileUpload,
  handleFileRemove,
}) => {
  useEffect(() => {
    if (superDoInputs) {
      superDoInputs.forEach((input) => {
        form.setFieldValue(input.inputId, input.defaultValue);
      });
    }
  }, [superDoInputs, form]);

  if (isEmpty(superDoInputs)) return <EmptyInfoText>Dieses Template muss noch definiert werden</EmptyInfoText>;

  return (
    <Form form={form}>
      {superDoInputs.map((input) => {
        if (input.type === 'SELECTION') {
          return (
            <Form.Item
              key={input.inputId}
              labelCol={{ span: 24 }}
              label={input.inputTitle}
              name={input.inputId}
              //rules={[{ required: true, message: 'Pflichtfeld' }]}
            >
              <BaseSelect
                options={
                  input.defaultSelectionValues
                    ? input.defaultSelectionValues.map((item) => ({ label: item, value: item }))
                    : []
                }
                defaultValue={input.defaultValue}
              />
            </Form.Item>
          );
        } else if (input.type === 'TEXTINPUT') {
          return (
            <Form.Item
              key={input.inputId}
              labelCol={{ span: 24 }}
              label={input.inputTitle}
              name={input.inputId}
              //rules={[{ required: true, message: 'Pflichtfeld' }]}
            >
              <BaseInput />
            </Form.Item>
          );
        } else if (input.type === 'TEXTAREA') {
          return (
            <Form.Item key={input.inputId} labelCol={{ span: 24 }} label={input.inputTitle} name={input.inputId}>
              <BaseInput.TextArea
                style={
                  input.inputSize === 'large'
                    ? {
                        height: 200,
                      }
                    : { height: 100 }
                }
              />
            </Form.Item>
          );
        } else if (input.type === 'FILE_UPLOAD') {
          return (
            <Form.Item key={input.inputId} labelCol={{ span: 24 }} label={input.inputTitle} name={input.inputId}>
              <SingleFileUploader
                fileData={uploadedFiles.filter((item) => item.inputId === input.inputId)[0]}
                onFileUploaded={(file) => handleFileUpload(file, input.inputId)}
                onFileRemove={handleFileRemove}
                backgroundColor="var(--background-color)"
                acceptedFileTypes={SupportedFileTypesForUploader}
              />
            </Form.Item>
          );
        }
        return null;
      })}
    </Form>
  );
};

export default GptTemplatesDetailInputControlls;
