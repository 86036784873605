import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  activate2faCode,
  authenticateWith2faCode,
  login,
  LoginRequest,
  LoginRequestWith2FA,
  logout,
  NewPasswordData,
  resetPassword,
  SecurityCodePayload,
  setNewPassword,
  signUp,
  SignUpRequest,
  verifyAuth,
  verifySecurityCode,
} from '@app/api/auth.api';
import {
  setAccessToken,
  setRedirectTo2FA,
  setSecurityToken,
  setSessionExpired,
  setUser,
} from '@app/store/slices/userSlice';
import { RootState } from '../store';
import { updateUser } from '@app/api/user.api';
import { resetSettingsState } from './settingsSlice';
import { setLoadedKnowledgeAreas } from './knowledgeAreaSlice';
import { resetHistorySettingsState } from './chatBoxSlice';

export interface AuthSlice {
  userIsLoggedIn: boolean;
}

const initialState: AuthSlice = {
  userIsLoggedIn: false,
};

export const doVerifyAuth = createAsyncThunk('auth/doVerifyAuth', async (_, { dispatch }) =>
  verifyAuth()
    .then((user) => {
      dispatch(setUser(user));
      return !!user;
    })
    .catch((error) => {
      console.log('****** doVerifyAuth error: ', error);
      dispatch(setUser(null));
      dispatch(setAccessToken(null));
      dispatch(setSecurityToken(null));
      dispatch(setSessionExpired(false));
      dispatch(resetSettingsState());
      dispatch(resetHistorySettingsState());
      dispatch(setLoadedKnowledgeAreas({ value: [] }));
      return false;
    }),
);

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then((res) => {
    dispatch(setUser(res.data.user));
    dispatch(setAccessToken(res.data.token));
    dispatch(setRedirectTo2FA(res.data.redirectTo2FA));
    dispatch(setSecurityToken(res.data.securityToken));
    dispatch(setSessionExpired(false));
    return { userIsValid: !!res.data.user, redirectTo2FA: res.data.redirectTo2FA };
  }),
);

export const doActivate2FA = createAsyncThunk(
  'auth/doActivate2FA',
  async (loginPayload: LoginRequestWith2FA, { dispatch }) =>
    activate2faCode(loginPayload.userId, loginPayload.twoFactorAuthenticationCode).then((res) => {
      dispatch(setUser(res.data.user));
      dispatch(setAccessToken(res.data.token));
      dispatch(setRedirectTo2FA(res.data.redirectTo2FA));
      dispatch(setSecurityToken(res.data.securityToken));
      dispatch(setSessionExpired(false));
      return !!res.data.user;
    }),
);

export const doLoginWith2FA = createAsyncThunk(
  'auth/doLoginWith2FA',
  async (loginPayload: LoginRequestWith2FA, { dispatch }) =>
    authenticateWith2faCode(loginPayload.userId, loginPayload.twoFactorAuthenticationCode).then((res) => {
      dispatch(setUser(res.data.user));
      dispatch(setAccessToken(res.data.token));
      dispatch(setRedirectTo2FA(res.data.redirectTo2FA));
      dispatch(setSecurityToken(res.data.securityToken));
      dispatch(setSessionExpired(false));
      return !!res.data.user;
    }),
);

export const doUpdateUserName = createAsyncThunk(
  'auth/doUpdateUserName',
  async ({ firstName, lastName }: { firstName: string; lastName: string }, { dispatch, getState }) => {
    const user = (getState() as RootState).user.user;
    if (user) {
      updateUser(user._id, { firstName, lastName }).then(() => {
        dispatch(setUser({ ...user, firstName, lastName }));
      });
    }
  },
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest, { dispatch }) =>
  signUp(signUpPayload).then((res) => {
    dispatch(setUser(res.data.user));
    dispatch(setAccessToken(res.data.token));
    dispatch(setSecurityToken(res.data.securityToken));
    dispatch(setSessionExpired(false));
    return !!res.data.user;
  }),
);

export const doResetPassword = createAsyncThunk('auth/doResetPassword', async (email?: string) => resetPassword(email));

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  logout().finally(() => {
    dispatch(setUser(null));
    dispatch(setAccessToken(null));
    dispatch(setSecurityToken(null));
    dispatch(setSessionExpired(false));
    dispatch(resetSettingsState());
    dispatch(resetHistorySettingsState());
    dispatch(setLoadedKnowledgeAreas({ value: [] }));
  });
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.userIsLoggedIn = action.payload.userIsValid;
    });
    builder.addCase(doVerifyAuth.fulfilled, (state, action) => {
      state.userIsLoggedIn = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.userIsLoggedIn = false;
    });
  },
});

export default authSlice.reducer;
