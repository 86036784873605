import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Message from './Message';
import SendMessage from './SendMessage';
import * as S from './GptChatBoxAnalyze.styles';
import { notificationController } from '@app/controllers/notificationController';
import { Button, Col, Form, Layout, Modal, Row, Space, Tooltip, message } from 'antd';
import { useParams } from 'react-router-dom';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import {
  executeGptQuery,
  executeGptQueryStreamWithFormData,
  executeKnowledgeBaseQueryStream,
  executeKnowledgeBaseQueryStreamPublic,
  executeToolAgentStream,
} from '@app/api/gpt.api';
import {
  DocumentTagStyled,
  ErrorWrapper,
  MessageDateWrapper,
  MessageWrapper,
  ModeTag,
  UploadedDocumentsWrapper,
} from './GptChatBox.styles';
import ScrollDownButton from '../common/buttons/Button/ScrollDownButton';
import { GPT_MODEL_SELECTION, INTERACTION_MODE } from '@app/utils/constants';
import {
  setaiInteractionMode,
  setSelectedChatId,
  setSourceAuditInteractionData,
} from '@app/store/slices/settingsSlice';
import {
  FilterSourceDocument,
  FilterValdityPeriod,
  MessageType,
  SourceDocumentType,
  SourceDocumentsSorted,
  SupportedDocumentType,
} from '@app/types/chatBoxTypes';
import KnowledgeMessage from './KnowledgeMessage';
import { useTranslation } from 'react-i18next';
import EmptyGptChatBox from './EmptyGptChatBox';
import { onCreateNewChatHistory, setLoadedChatHistory, setTriggerActionId } from '@app/store/slices/chatBoxSlice';
import {
  AgentActionMessage,
  ChatResponseMessage,
  KnowledgeAnswerAnalysisMessage,
  isAgentActionMessage,
  isKnowledgeAnswerAnalysisMessage,
} from '@app/types/socketMessageTypes';
import { Socket } from 'socket.io-client';
import { useResponsive } from '@app/hooks/useResponsive';
import moment from 'moment';
import { checkConnectionIsAlive } from '@app/api/general.api';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { IconLayoutSidebarRightExpand, IconX } from '@tabler/icons-react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import SourceAuditDrawer from './SourceAuditDrawer';
import {
  deleteMessagesInChatHistory,
  readChatHistory,
  readOrCreateChatHistory,
  resetMessagesInChatHistory,
  updateChatHistory,
} from '@app/api/chatHistory.api';
import { createAiGeneratedImages } from '@app/api/imageGeneration.api';
import { ChatMode, IChatHistory } from '@app/domain/ChatHistoryModel';
import ChatHistoryDrawer, { NewConversationTitle } from './SidebarConversations/ChatHistoryDrawer';
import FilterDrawer from './SidebarFilter/FilterDrawer';
import LlmSetupDrawer from './LlmSetup/LlmSetupDrawer';
import { GptChatBoxFilter } from './GptChatBoxFilter';
import { truncateString } from '@app/utils/stringHelpers';
import { v4 as uuidv4 } from 'uuid';
import { LoadingSpin } from '../common/LoadingSpin';
import { EXTERNAL_USER_ID } from '@app/utils/constants';
import { DocumentInterface } from '@app/types/generalTypes';
import { useHandleMultipleFileUploadsInChat } from '@app/hooks/useHandleMultipleFileUploadsInChat';
import { UploadFile } from 'antd/es/upload/interface';
import { createChatSocketInstance } from '@app/services/socketIOChat.service';

export type SourceDrawerRelatedText = { question: string; answer: string; messageId: string };

export interface PropTypes {
  chatMode: ChatMode;
  presetToAnalyzeFileIds?: string[];
  presetChatId?: string;
  presetEntityId?: string;
  presetQuestion?: string;
  onChatHistoryCreated?: (history: IChatHistory) => void;
  onInlineSourceClick?: (source: SourceDocumentType) => void;
}

const GptChatBox: React.FC<PropTypes> = ({
  chatMode,
  presetToAnalyzeFileIds,
  presetChatId,
  presetEntityId,
  presetQuestion,
  onChatHistoryCreated,
  onInlineSourceClick,
}) => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [messageIsProcessing, setMessageIsProcessing] = useState(false);
  const [errorOnMessageProcessing, setErrorOnMessageProcessing] = useState(false);
  const [showScrollDownButton, setShowScrollDownButton] = useState<boolean>(false);
  const [connectionAlive, setConnectionAlive] = useState(true);
  const [showSourceDrawer, setShowSourceDrawer] = useState(false);
  const [showChatbar, setShowChatbar] = useState(false);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [showLlmSetup, setShowLlmSetup] = useState(false);
  const [toShowDrawerSourceDocuments, setToShowDrawerSourceDocuments] = useState<SourceDocumentsSorted[]>([]);
  const [showValidityPeriodFields, setShowValidityPeriodFields] = useState(false);

  const [sourceDrawerRelatedText, setSourceDrawerRelatedText] = useState<SourceDrawerRelatedText | null>(null);

  const [selectedKnowledgeArea, setSelectedKnowledgeArea] = useState<KnowledgeAreaModel | null>(null);

  const [initialized, setInitialized] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [form] = Form.useForm();

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const autoScrollEnabledRef = useRef<boolean>(false);

  const user = useAppSelector((state) => state.user.user);
  const companyState = useAppSelector((state) => state.company);
  const {
    gptChatBoxLLM,
    modelLanguage,
    modelResponseLength,
    conversationMemoryKnowledgeActive,
    enableQuestionOptimization,
    advancedKnowledgeRAGActive,
    aiInteractionMode,
    selectedChatId,
    customSystemPrompt,
  } = useAppSelector((state) => state.settings);

  const [activeAiInteractionMode, setActiveAiInteractionMode] = useState<INTERACTION_MODE>(
    aiInteractionMode || 'AI_ONLY',
  );

  const { triggerActionId, intialAppDataLoaded } = useAppSelector((state) => state.chatBox);
  const { loadedKnowledgeAreas } = useAppSelector((state) => state.knowledgeArea);
  const loadedChatHistory = useAppSelector((state) => state.chatBox.loadedChatHistory);
  const { appName } = useAppSelector((state) => state.appLayout);
  const { isPublicAccessMode } = useAppSelector((state) => state.authExternalAccessToken);

  const knowledgeAreaFilters = useAppSelector((state) => state.knowledgeAreaFilter);

  const { mobileOnly, tabletOnly } = useResponsive();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const chatId = useRef<string>('');

  const {
    alreadyUploadedFiles,
    uploadChatFiles,
    onNewFileUploaded,
    uploaderSelectedFiles,
    onDeleteUploadedFile,
    onDeleteUploaderSelectedFile,
    onDeleteAllChatFiles,
  } = useHandleMultipleFileUploadsInChat({
    selectedChatId: chatId.current,
    onProcessQueueError: (error) => {
      if (error instanceof Error) {
        notificationController.error({ message: error.message });
      } else if (typeof error === 'string') {
        notificationController.error({ message: error });
      } else {
        notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
      }
    },
  });

  const internetSearchActive = activeAiInteractionMode === 'WEB';
  const imageCreationModeActive = activeAiInteractionMode === 'IMAGE_GENERATION';
  const multiDocumentMode = activeAiInteractionMode === 'DOCUMENT_ANALYSIS';

  const knowledgeBaseMode = chatMode === 'KNOWLEDGE';
  const agentSearchMode = chatMode === 'AGENT';
  const chatGptMode = chatMode === 'CHAT_GPT';
  const sidebarDocumentReviewMode = chatMode === 'SIDEBAR_DOCUMENT_REVIEW';

  const waitText = '`▍`';

  const receivingSourceDocumentsUnique = useRef<SourceDocumentType[]>([]);
  const receivingSourceDocumentsAll = useRef<SourceDocumentType[]>([]);
  const updatedMessages = useRef<MessageType[]>([]);
  const socketIO = useRef<Socket | null>(null);
  const windowIsShown = useRef<boolean>(false);
  const windowBluredTimestamp = useRef<number>(0);
  const sourceDrawerSelectedFilterSourceDocuments = useRef<FilterSourceDocument[]>([]);

  const chatHistory = useRef<IChatHistory | null>(null);

  const abortControllerRef = useRef<AbortController | null>(null);

  const { id: selectedKnowledgeAreaId } = useParams();

  const readChatHistoryData = async ({
    toReadChatId,
    onLoadCallback,
  }: {
    toReadChatId?: string;
    onLoadCallback?: (history: IChatHistory | null) => void;
  }) => {
    if (knowledgeBaseMode && !selectedKnowledgeAreaId) return;
    if (user?._id) {
      try {
        setRefreshing(true);
        const areaId = selectedKnowledgeAreaId || presetEntityId || undefined;
        const history = toReadChatId
          ? await readChatHistory(user?._id, toReadChatId)
          : await readOrCreateChatHistory(user?._id, chatMode, areaId);

        chatHistory.current = history;

        if (!toReadChatId && history) onChatHistoryCreated?.(history);

        if (history?._id) {
          const newChatHistoryCreated =
            history.createdAt &&
            moment().diff(moment.unix(history.createdAt), 'seconds') < 5 &&
            !loadedChatHistory.some((item) => item._id === history._id);
          if (newChatHistoryCreated && chatGptMode) {
            dispatch(setLoadedChatHistory({ value: [history, ...loadedChatHistory] }));
          }
          chatId.current = history._id;

          if (!toReadChatId && chatGptMode) {
            // setSelectedChatId was called in the history drawer
            dispatch(setSelectedChatId({ value: history?._id }));
          }

          if (history.aiInteractionMode) {
            dispatch(setaiInteractionMode({ value: history.aiInteractionMode }));
          } else {
            dispatch(setaiInteractionMode({ value: 'AI_ONLY' }));
          }

          updatedMessages.current = history.messages.map((message) => {
            const sourceDocumentsAll = message.sourceDocuments
              ? message.sourceDocuments.map((item: DocumentInterface<Record<string, any>>) => mapDocumentSources(item))
              : [];
            return {
              text: message.message,
              uid: message.type === 'human' ? history.userId : 'ai-assistant',
              name: message.type === 'human' ? user?.firstName ?? 'User' : appName,
              avatar: message.type === 'human' ? user?.profileImage?.smallUrl ?? '' : '',
              attachedFileName: message.attachedFileName,
              imageUrls: message.generatedImageUrls || [],
              numberSourceDocumentsFromHistory: message.numberSourceDocuments,
              sourceDocumentsAll,
              sourceDocumentsUnique: sourceDocumentsAll.filter((obj, index, arr) => {
                return arr.findIndex((item) => item.docTitle === obj.docTitle) === index;
              }),
              createdAt: message.createdAt,
              id: uuidv4(),
              knowledgeAnswerAnalysis:
                message.answerIsGrounded !== undefined
                  ? {
                      loading: false,
                      answerIsGrounded: message.answerIsGrounded || false,
                      score: message.score,
                      reason: message.reason,
                    }
                  : undefined,
            };
          });

          setMessages(updatedMessages.current);

          history.messages.length &&
            setTimeout(() => {
              handleScrollDown();
            }, 750);
        }
        onLoadCallback?.(history);
      } catch (error) {
        let errorMessage = t('requestFailedRetry');
        if (error instanceof Error) {
          errorMessage = error.message;
        } else if (typeof error === 'string') {
          errorMessage = error;
        }

        notificationController.error({
          message: errorMessage,
        });
      } finally {
        setRefreshing(false);
      }
    } else if (isPublicAccessMode) {
      chatId.current = uuidv4();
      onLoadCallback?.(null);
    }
  };

  useEffect(() => {
    window.addEventListener('focus', onWindowFocus);
    window.addEventListener('blur', onWindowBlur);

    return () => {
      window.removeEventListener('focus', onWindowFocus);
      window.removeEventListener('blur', onWindowBlur);
    };
  }, []);

  const debauncedReadChatHistoryData = debounce(readChatHistoryData, 100);

  useEffect(() => {
    if (intialAppDataLoaded && !chatHistory.current) {
      debauncedReadChatHistoryData({
        toReadChatId: chatGptMode ? selectedChatId || undefined : sidebarDocumentReviewMode ? presetChatId : undefined,
        onLoadCallback: () => {
          form.setFieldsValue({ gptChatBoxLLM });
          form.setFieldsValue({ conversationMemoryKnowledgeActive });
          form.setFieldsValue({ advancedKnowledgeRAGActive });
          form.setFieldsValue({ enableQuestionOptimization });
          setInitialized(true);
        },
      });
    }
  }, [chatMode, intialAppDataLoaded]);

  useEffect(() => {
    if (chatId.current && aiInteractionMode) {
      const updateInteractionMode = async () => {
        await updateChatHistory(chatId.current, { aiInteractionMode });
        setActiveAiInteractionMode(aiInteractionMode);
      };
      updateInteractionMode();
    }
  }, [aiInteractionMode]);

  useEffect(() => {
    if (selectedKnowledgeAreaId) {
      if (initialized) {
        onSwitchSpace();
      }

      const foundKnowledgeArea = loadedKnowledgeAreas.find((item) => item._id === selectedKnowledgeAreaId);
      setSelectedKnowledgeArea(foundKnowledgeArea || null);

      setShowValidityPeriodFields(
        foundKnowledgeArea?.additionalDocumentFields?.some((item) => item === 'VALIDITY_PERIOD') || false,
      );
    }
  }, [selectedKnowledgeAreaId]);

  useEffect(() => {
    if (selectedKnowledgeAreaId) {
      const foundKnowledgeArea = loadedKnowledgeAreas.find((item) => item._id === selectedKnowledgeAreaId);
      setSelectedKnowledgeArea(foundKnowledgeArea || null);

      setShowValidityPeriodFields(
        foundKnowledgeArea?.additionalDocumentFields?.some((item) => item === 'VALIDITY_PERIOD') || false,
      );
    }
  }, [selectedKnowledgeAreaId, loadedKnowledgeAreas]);

  useEffect(() => {
    if (initialized && selectedChatId) {
      onSwitchSpace(selectedChatId);
    }
  }, [selectedChatId]);

  useEffect(() => {
    if (initialized && chatId.current) {
      connectWebSocket(chatId.current);
      return () => {
        console.log('### cleanup user-leaved', chatId.current);
        socketIO.current?.emit('user-leaved', { userId: user?._id, chatId: chatId.current });
        socketIO.current?.off('new-message', onNewMessageFromSocket);
        socketIO.current?.close();
      };
    }
  }, [initialized]);

  useEffect(() => {
    if (triggerActionId === 'RESET_MESSAGES') {
      onReset();
      dispatch(setTriggerActionId({ value: null }));
    } else if (triggerActionId === 'SHOW_LLM_SETTINGS_DRAWER') {
      setShowLlmSetup(true);
      dispatch(setTriggerActionId({ value: null }));
    }
  }, [dispatch, triggerActionId]);

  const testConnection = async () => {
    try {
      const connectionAlive = await checkConnectionIsAlive();
      setConnectionAlive(connectionAlive && socketIO.current?.active === true);
    } catch (error) {
      setConnectionAlive(false);
    }
  };

  const onWindowFocus = () => {
    windowIsShown.current = true;
    if (windowBluredTimestamp.current) {
      const minutesWasBlured = moment().diff(moment.unix(windowBluredTimestamp.current), 'minutes');
      if (minutesWasBlured > 10) {
        window.location.reload();
      }
    }
  };

  const onWindowBlur = () => {
    windowIsShown.current = false;
    windowBluredTimestamp.current = moment().unix();
  };

  const scrollDown = () => {
    if (autoScrollEnabledRef.current) {
      messagesEndRef.current?.scrollIntoView(true);
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const bottomTolerance = 5;

      if (scrollTop + clientHeight < scrollHeight - bottomTolerance) {
        autoScrollEnabledRef.current = false;
        setShowScrollDownButton(true);
      } else {
        autoScrollEnabledRef.current = true;
        setShowScrollDownButton(false);
      }
    }
  };

  const handleScrollDown = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleLLMNewToken = (token: string, isFirstMessage: boolean) => {
    if (isFirstMessage) {
      const foundActiveMessage = updatedMessages.current.find((m) => m.text === waitText);
      const updatedMessagesNew = updatedMessages.current.filter((m) => m.text !== waitText);
      const newMessage: MessageType = {
        text: token,
        name: appName,
        avatar: '',
        createdAt: moment().unix(),
        uid: 'chatgptagen123',
        sourceDocumentsUnique: receivingSourceDocumentsUnique.current,
        sourceDocumentsAll: receivingSourceDocumentsAll.current,
        isGenerating: true,
        messageIsLoading: false,
        agentToolMessages: foundActiveMessage?.agentToolMessages || [],
        id: uuidv4(),
      };
      updatedMessages.current = [...updatedMessagesNew, newMessage];
      setMessages(updatedMessages.current);
    } else {
      const notEmptyMessage = Boolean(token && token.length > 0);
      updatedMessages.current = updatedMessages.current.map((message) => {
        return message.isGenerating
          ? {
              ...message,
              ...(notEmptyMessage && { agentToolMessages: [] }),
              text: message.text + token,
            }
          : { ...message };
      });
      setMessages(updatedMessages.current);
    }
    scrollDown();
  };

  const mapDocumentSources = (item: DocumentInterface<Record<string, any>>): SourceDocumentType => {
    const isWebsite = item.metadata?.type === 'website';
    return {
      type: item.metadata?.type ?? 'pdf',
      pageContent: item.pageContent.replace(/(#+\s*|\*{1,2}[^*]+\*{1,2})/g, ''),
      docTitle: isWebsite
        ? item.metadata?.source ?? '-'
        : item.metadata
          ? item.metadata?.title
            ? item.metadata?.title
            : item.metadata['pdf.info.Title']
          : '',
      source: item.metadata?.source ?? '-',
      page: item.metadata?.page,
      fileStoragePath: item.metadata?.fileStoragePath,
      chunkId: item.metadata?.chunkId,
      shortChunkId: item.metadata?.shortChunkId,
      agentToolSteps: item.metadata?.agentToolSteps,
      documentCreatedDate: item.metadata?.createdDate
        ? moment(item.metadata?.createdDate).format('DD.MM.YY HH:mm')
        : undefined,
      ingestedDate: item.metadata?.timestampIngested
        ? moment.unix(item.metadata?.timestampIngested).format('DD.MM.YY HH:mm')
        : undefined,
    };
  };

  const handleReceiveSourceDocuments = (sources: DocumentInterface<Record<string, any>>[]) => {
    if (sources) {
      try {
        receivingSourceDocumentsAll.current = sources.map((item: DocumentInterface<Record<string, any>>) =>
          mapDocumentSources(item),
        );

        const uniquesSources = receivingSourceDocumentsAll.current.filter((obj, index, arr) => {
          return arr.findIndex((item) => item.docTitle === obj.docTitle) === index;
        });

        receivingSourceDocumentsUnique.current = uniquesSources;
      } catch (error) {
        console.error('****** handleReceiveSourceDocuments error: ', error);
      }
    }
  };

  const getSourceDocumentEntry = (messageId: string, shortChunkId: string) => {
    if (receivingSourceDocumentsAll.current.length) {
      const foundSource = receivingSourceDocumentsAll.current.find((item) => item.shortChunkId === shortChunkId);
      if (foundSource) {
        return foundSource;
      }
    }
    const foundMessage = updatedMessages.current.find((item) => item.id === messageId);
    if (foundMessage) {
      const foundSource = foundMessage.sourceDocumentsAll?.find((item) => item.shortChunkId === shortChunkId);
      return foundSource;
    }
  };

  const handleStreamDone = async () => {
    setMessages(
      updatedMessages.current.map((message) =>
        message.isGenerating
          ? {
              ...message,
              isGenerating: false,
              sourceDocumentsUnique: receivingSourceDocumentsUnique.current,
              sourceDocumentsAll: receivingSourceDocumentsAll.current,
            }
          : { ...message },
      ),
    );
    setMessageIsProcessing(false);
    scrollDown();
  };

  const handleToolActionMessage = async (value: AgentActionMessage) => {
    const foundActiveMessage = updatedMessages.current.find(
      (m) => m.text === waitText || m.isGenerating || m.messageIsLoading,
    );
    if (foundActiveMessage) {
      foundActiveMessage.agentToolMessages = [value];
      foundActiveMessage.messageIsLoading = false;
      updatedMessages.current = [...updatedMessages.current];
      setMessages(updatedMessages.current);
    } else {
      const updatedMessagesNew = updatedMessages.current.filter((m) => m.text !== waitText);
      const newMessage: MessageType = {
        text: waitText,
        name: appName,
        avatar: '',
        createdAt: moment().unix(),
        uid: 'chatgptagen123',
        sourceDocumentsUnique: receivingSourceDocumentsUnique.current,
        sourceDocumentsAll: receivingSourceDocumentsAll.current,
        isGenerating: true,
        messageIsLoading: false,
        id: uuidv4(),
        agentToolMessages: [value],
      };
      updatedMessages.current = [...updatedMessagesNew, newMessage];
      setMessages(updatedMessages.current);
    }
  };

  const handleKnowledgeQueryAnalysisMessage = async (value: KnowledgeAnswerAnalysisMessage) => {
    if (value.actionType === 'ANALYSIS_STARTED') {
      const lastAnswerMessage = updatedMessages.current[updatedMessages.current.length - 1];
      lastAnswerMessage.knowledgeAnswerAnalysis = { loading: true };
      setMessages(updatedMessages.current);
    } else if (
      value.actionType === 'ANALYSIS_FINISHED_SUCCESS' ||
      value.actionType === 'ANALYSIS_FINISHED_WITH_ERROR'
    ) {
      const lastAnswerMessage = updatedMessages.current[updatedMessages.current.length - 1];
      lastAnswerMessage.knowledgeAnswerAnalysis = { ...value.result, loading: false };
      setMessages(updatedMessages.current);
    }
  };

  const generateTitleForChat = async () => {
    if (chatGptMode && updatedMessages.current.length === 1) {
      if (chatId.current && chatHistory.current?._id) {
        try {
          let firstMessageAsTitle = updatedMessages.current[0].text;
          if (firstMessageAsTitle.length <= 100) {
            firstMessageAsTitle = truncateString(firstMessageAsTitle, 30);
          } else {
            firstMessageAsTitle = await executeGptQuery({
              queryText: `Summarise this message in a maximum of three words: ${firstMessageAsTitle}`,
              aiModelName: gptChatBoxLLM,
            });
          }
          await updateChatHistory(chatHistory.current._id, { title: firstMessageAsTitle });

          const updatedChatHistory = loadedChatHistory.map((history) => {
            if (history._id === chatHistory.current?._id) {
              return { ...history, title: firstMessageAsTitle };
            } else return history;
          });

          dispatch(setLoadedChatHistory({ value: updatedChatHistory }));
        } catch (error) {
          console.log('****** create chat title error: ', error);
        }
      }
    }
  };

  const updateInteractionTimestampForChat = async () => {
    const updatedChatHistory = loadedChatHistory.map((history) => {
      if (history._id === chatHistory.current?._id) {
        return { ...history, lastInteractionTimestamp: moment().unix() };
      } else return history;
    });

    dispatch(setLoadedChatHistory({ value: updatedChatHistory }));
  };

  const checkIfHistoryExists = async () => {
    if (chatGptMode && loadedChatHistory.length === 0) {
      try {
        await dispatch(onCreateNewChatHistory(NewConversationTitle, chatMode, aiInteractionMode));
      } catch (error) {
        console.log('****** checkIfHistoryExists error: ', error);
      }
    }
  };

  const onNewMessageFromSocket = (value: ChatResponseMessage | AgentActionMessage | KnowledgeAnswerAnalysisMessage) => {
    const { messageType } = value;
    if (isAgentActionMessage(value)) {
      if (messageType === 'AGENT_ACTION') {
        handleToolActionMessage(value);
      }
    } else if (isKnowledgeAnswerAnalysisMessage(value)) {
      handleKnowledgeQueryAnalysisMessage(value);
    } else {
      const { isFirstMessage, sourceDocuments, token } = value;
      if (messageType === 'NEW_TOKEN') {
        handleLLMNewToken(token ?? '', isFirstMessage || false);
      } else if (messageType === 'SOURCE_DOCUMENTS' && sourceDocuments) {
        handleReceiveSourceDocuments(sourceDocuments);
      } else if (messageType === 'STREAM_DONE') {
        handleStreamDone();
      }
    }
  };

  const connectWebSocket = (_chatId: string, connectedCallback?: () => void) => {
    createChatSocketInstance((socket) => {
      socketIO.current?.off('new-message', onNewMessageFromSocket);
      socketIO.current = socket;
      socketIO.current?.on('new-message', onNewMessageFromSocket);
      socketIO.current?.emit('user-joined', { userId: user?._id, chatId: _chatId });
      connectedCallback?.();
    });
  };

  const onReset = async (showMessage = true) => {
    if (chatId.current) {
      const resetChat = async () => {
        try {
          setRefreshing(true);
          await resetMessagesInChatHistory(chatId.current);

          const resetState = () => {
            updatedMessages.current = [];
            setMessages([]);
            onDeleteAllChatFiles();
            showMessage && message.success(t('resetedHistory'));
          };

          if (socketIO.current && !socketIO.current.connected) {
            connectWebSocket(chatId.current, resetState);
          } else {
            resetState();
          }
        } catch (error) {
          let errorMessage = t('requestFailedRetry');
          if (error instanceof Error) {
            errorMessage = error.message;
          } else if (typeof error === 'string') {
            errorMessage = error;
          }

          notificationController.error({
            message: errorMessage,
          });
        } finally {
          setRefreshing(false);
        }
      };

      if (messages.length > 0) {
        Modal.confirm({
          title: t('restartAiChat'),
          icon: <ExclamationCircleOutlined />,
          content: t('restartAiChatAreYouSure'),
          okText: t('yes'),
          okType: 'danger',
          cancelText: t('no'),
          onOk() {
            resetChat();
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      } else {
        resetChat();
      }
    }
  };

  const onSwitchSpace = async (toReadChatId?: string) => {
    const oldChatId = chatId.current;

    readChatHistoryData({
      toReadChatId,
      onLoadCallback: (newHistory) => {
        const newChatId = newHistory?._id;
        if (newChatId) {
          if (socketIO.current && !socketIO.current.connected) {
            connectWebSocket(newChatId);
          } else {
            oldChatId && socketIO.current?.emit('user-leaved', { userId: user?._id, chatId: oldChatId });
            socketIO.current?.emit('user-joined', { userId: user?._id, chatId: newChatId });
          }
        }
      },
    });
  };

  const executeImageGeneration = async (message: string, messages: MessageType[]) => {
    const updatedMessagesNew = messages.filter((m) => m.text !== waitText);

    const imagesUrls = await createAiGeneratedImages({ queryText: message, chatId: chatId.current });

    const newMessage: MessageType = {
      text: '',
      name: appName,
      avatar: '',
      createdAt: moment().unix(),
      uid: 'chatgptagen123',
      imageUrls: imagesUrls,
      messageIsLoading: false,
      id: uuidv4(),
    };

    updatedMessages.current = [...updatedMessagesNew, newMessage];
    setMessages(updatedMessages.current);
    setMessageIsProcessing(false);
    setErrorOnMessageProcessing(false);
    scrollDown();
  };

  /**
   * Handles different API calls for different modes
   */
  const onSendMessage = (message: string, uploadedFiles?: UploadFile[], preventAddNewUserMessage = false) => {
    if (knowledgeBaseMode && !selectedKnowledgeArea && !isPublicAccessMode) {
      Modal.confirm({
        title: t('knowledgeAreaNotSelected'),
        icon: <InfoCircleOutlined />,
        content: t('pleaseSelectKnowledgeArea'),
        okText: t('ok'),
      });
      return;
    }
    if (messages.length > 1000) {
      Modal.confirm({
        title: t('common.info'),
        icon: <InfoCircleOutlined />,
        content: t('toManyChatMessages', { number: 1000 }),
        okText: t('ok'),
      });
      return;
    }
    if (chatGptMode && loadedChatHistory.length === 0 && !isPublicAccessMode) {
      Modal.confirm({
        title: t('common.info'),
        icon: <InfoCircleOutlined />,
        content: t('conversationIsCreated'),
        okText: t('ok'),
      });
      checkIfHistoryExists();
      return;
    }
    if (multiDocumentMode) {
      if ((uploadedFiles && uploadedFiles.length > 5) || alreadyUploadedFiles.length > 5) {
        notificationController.warning({
          message: t('analysisFileSelectionWarning'),
        });
        return;
      }
    }
    if (!preventAddNewUserMessage) {
      const newMessage: MessageType = {
        text: message,
        name: user?.firstName || (isPublicAccessMode ? 'External User' : 'User'),
        avatar: user?.profileImage?.smallUrl ?? '',
        createdAt: moment().unix(),
        uid: isPublicAccessMode ? EXTERNAL_USER_ID : user?._id ?? '?',
        //attachedFileName: uploadedFile?.name,
        id: uuidv4(),
      };
      updatedMessages.current = [...messages, newMessage];
      setMessages(updatedMessages.current);
      generateTitleForChat();
    }

    const sendToApi = async () => {
      const waitMessage: MessageType = {
        text: waitText,
        name: appName,
        avatar: '',
        createdAt: moment().unix(),
        uid: 'chatgptagen123',
        messageIsLoading: true,
        id: uuidv4(),
      };
      updatedMessages.current = [...updatedMessages.current, waitMessage];
      setMessages(updatedMessages.current);

      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        autoScrollEnabledRef.current = true;
      }, 500);

      try {
        setMessageIsProcessing(true);
        setErrorOnMessageProcessing(false);
        receivingSourceDocumentsUnique.current = [];
        receivingSourceDocumentsAll.current = [];

        abortControllerRef.current = new AbortController();

        if (knowledgeBaseMode) {
          if (isPublicAccessMode) {
            await executeKnowledgeBaseQueryStreamPublic(
              {
                queryText: `${message}`,
                namespace: selectedKnowledgeArea?.thematicAreaId ?? '',
                chatId: chatId.current,
                aiModelName: gptChatBoxLLM,
                sourceFilters: sourceDrawerSelectedFilterSourceDocuments.current,
              },
              abortControllerRef.current.signal,
            );
          } else {
            let sourceFilters: FilterSourceDocument[] = [];
            const documentValdityPeriodFilter: FilterValdityPeriod = {};

            if (selectedKnowledgeAreaId && knowledgeAreaFilters[selectedKnowledgeAreaId]) {
              const knowledgeAreaFilter = knowledgeAreaFilters[selectedKnowledgeAreaId];
              if (knowledgeAreaFilter.selectedDocuments.length) {
                sourceFilters = knowledgeAreaFilter.selectedDocuments.map((doc) => ({
                  title: doc.label,
                  type: doc.type as SupportedDocumentType,
                }));
              }
              if (knowledgeAreaFilter.selectedWebsites.length) {
                sourceFilters.push(
                  ...knowledgeAreaFilter.selectedWebsites.map((doc) => ({
                    title: doc.url ?? doc.label,
                    type: doc.type as SupportedDocumentType,
                  })),
                );
              }
              if (knowledgeAreaFilter.validFrom) {
                documentValdityPeriodFilter.validFrom = knowledgeAreaFilter.validFrom;
              }
              if (knowledgeAreaFilter.validUntil) {
                documentValdityPeriodFilter.validUntil = knowledgeAreaFilter.validUntil;
              }
            }
            await executeKnowledgeBaseQueryStream(
              {
                queryText: `${message}`,
                namespace: selectedKnowledgeArea?.thematicAreaId ?? '',
                chatId: chatId.current,
                aiModelName: gptChatBoxLLM,
                userId: user?._id ?? '',
                companyId: companyState._id ?? '',
                sourceFilters,
                documentValdityPeriodFilter,
                enableQuestionOptimization,
                enableConversationMemory: conversationMemoryKnowledgeActive,
                enableAdvancedKnowledgeRAG: advancedKnowledgeRAGActive,
                outputLanguage: modelLanguage,
                customSystemPrompt: customSystemPrompt === 'default' ? undefined : customSystemPrompt?.trim(),
                modelResponseLength,
              },
              abortControllerRef.current.signal,
            );
          }
          return;
        } else if (agentSearchMode || internetSearchActive) {
          await executeToolAgentStream(
            {
              queryText: message,
              chatId: chatId.current,
              aiModelName: gptChatBoxLLM,
              userId: user?._id ?? '',
              companyId: companyState._id ?? '',
              outputLanguage: modelLanguage,
              modelResponseLength,
              customSystemPrompt: customSystemPrompt === 'default' ? undefined : customSystemPrompt?.trim(),
            },
            abortControllerRef.current.signal,
          );
          return;
        } else {
          if (imageCreationModeActive) {
            await executeImageGeneration(message, updatedMessages.current);
            return;
          } else {
            let toAnalyzeFileIds: string[] = [];

            if (uploaderSelectedFiles.length) {
              handleToolActionMessage({
                actionType: 'TOOL_STARTED',
                contextType: 'general',
                messageType: 'AGENT_ACTION',
                message: uploaderSelectedFiles.length > 1 ? 'Uploading files' : 'Uploading file',
              });
              const justUploadedFiles = await uploadChatFiles();
              if (justUploadedFiles?.length) {
                toAnalyzeFileIds = justUploadedFiles?.filter((file) => !!file._id).map((file) => file._id ?? '') || [];
              }
            } else if (alreadyUploadedFiles) {
              toAnalyzeFileIds = alreadyUploadedFiles.map((file) => file._id ?? '') || [];
            }

            if (presetToAnalyzeFileIds?.length) {
              toAnalyzeFileIds = [...toAnalyzeFileIds, ...presetToAnalyzeFileIds];
            }

            const formData = new FormData();

            formData.append(
              `queryGptData`,
              JSON.stringify({
                queryText: `${message}`,
                chatId: chatId.current,
                enableHistory: true,
                aiModelName: gptChatBoxLLM,
                userId: user?._id ?? '',
                companyId: companyState._id ?? '',
                outputLanguage: modelLanguage,
                modelResponseLength,
                multiDocumentMode: multiDocumentMode || sidebarDocumentReviewMode,
                customSystemPrompt: customSystemPrompt === 'default' ? undefined : customSystemPrompt?.trim(),
                toAnalyzeFileIds,
              }),
            );

            await executeGptQueryStreamWithFormData(formData, abortControllerRef.current.signal);
            return;
          }
        }
      } catch (error) {
        let errorMessage = t('requestFailedRetry');
        if (error instanceof Error) {
          errorMessage = error.message;
        } else if (typeof error === 'string') {
          errorMessage = error;
        }

        const wasCancelled = ['canceled', 'AbortError'].includes(errorMessage);

        updatedMessages.current = updatedMessages.current.map((message) =>
          message.isGenerating || message.messageIsLoading || message.text === waitText
            ? {
                ...message,
                isGenerating: false,
                messageIsLoading: false,
                errorOnMessageProcessing: true,
                wasCancelled,
                sourceDocumentsUnique: receivingSourceDocumentsUnique.current,
                sourceDocumentsAll: receivingSourceDocumentsAll.current,
                agentToolMessages: [],
                text: message.text.replace(waitText, ''),
              }
            : { ...message },
        );
        setMessages(updatedMessages.current);
        setMessageIsProcessing(false);

        if (wasCancelled) {
          return;
        }
        notificationController.error({
          message: errorMessage,
        });
        setErrorOnMessageProcessing(true);
      } finally {
        sourceDrawerSelectedFilterSourceDocuments.current = [];
      }
    };

    updateInteractionTimestampForChat();
    sendToApi();
  };

  const handleOnStopMessageStream = () => {
    socketIO.current?.emit('request-abort-message-stream', { userId: user?._id, chatId: chatId.current });
    abortControllerRef.current?.abort();
  };

  const openSourceDrawer = (allSources: SourceDocumentType[], question: string, answer: string, messageId: string) => {
    const sortedDocuments: SourceDocumentsSorted[] = allSources.reduce(
      (acc: SourceDocumentsSorted[], docItem: SourceDocumentType) => {
        if (!acc.some((item: SourceDocumentsSorted) => item.title === docItem.docTitle)) {
          acc.push({
            title: docItem.docTitle ?? docItem.source ?? '-',
            type: docItem.type,
            agentToolSteps: docItem.agentToolSteps,
            documents: allSources.filter((item) => item.docTitle === docItem.docTitle),
          });
        }
        return acc;
      },
      [],
    );

    setToShowDrawerSourceDocuments(sortedDocuments);
    setSourceDrawerRelatedText({ question, answer, messageId });
    setShowSourceDrawer(true);
  };

  const loadingMessageExists = useMemo(
    () => updatedMessages.current.some((item) => item.messageIsLoading),
    [updatedMessages],
  );

  const onCloseSourceDrawer = () => {
    setShowSourceDrawer(false);
  };

  const getHeaderTitle = () => {
    if (knowledgeBaseMode) return null;
    else if (agentSearchMode) return 'GPT4 Agenten Modus';
    else if (multiDocumentMode) return 'AI-Chat';
    return 'AI-Chat';
  };

  const onGenerateAnswerWithSelectedSources = async (messageId: string, selectedSources: SourceDocumentsSorted[]) => {
    if (knowledgeBaseMode && chatHistory.current?._id) {
      const messageIndex = messages.findIndex((message) => message.id === messageId);
      if (messageIndex >= 0) {
        const indexBeforeMessage = messageIndex > 0 ? messageIndex - 1 : 0;
        const beforeMessage = messages[indexBeforeMessage];

        const sourcesFilter: FilterSourceDocument[] = [];
        selectedSources.forEach((item) => {
          const firstDoc = item.documents[0];
          if (
            firstDoc &&
            !sourcesFilter.some((filter) => filter.title === firstDoc.docTitle && filter.type === firstDoc.type)
          ) {
            sourcesFilter.push({ title: firstDoc.docTitle, type: firstDoc.type });
          }
        });
        sourceDrawerSelectedFilterSourceDocuments.current = sourcesFilter;
        updatedMessages.current = [...messages.splice(0, messageIndex)];
        setMessages(updatedMessages.current);
        try {
          // delete also question because the backend store always question and answer
          await deleteMessagesInChatHistory(chatHistory.current._id, indexBeforeMessage);
        } catch (error) {
          console.error('Error in deleteMessagesInChatHistory', error);
        }
        onSendMessage(beforeMessage.text, undefined, true);
      }
    }
  };

  const onUpdateMessageText = async (messageId: string, newText: string) => {
    if (loadingMessageExists || messageIsProcessing) return;
    const messageIndex = messages.findIndex((message) => message.id === messageId);
    if (messageIndex >= 0) {
      const messageIndexSliceLocal = messageIndex + 1;
      updatedMessages.current = [...messages.splice(0, messageIndexSliceLocal)];
      updatedMessages.current = updatedMessages.current.map((message) => {
        if (message.id === messageId) {
          return { ...message, text: newText };
        }
        return message;
      });
      setMessages(updatedMessages.current);
      if (chatHistory.current?._id) {
        try {
          // delete also question because the backend store always question and answer
          await deleteMessagesInChatHistory(chatHistory.current._id, messageIndex);
        } catch (error) {
          console.error('Error in deleteMessagesInChatHistory', error);
        }
      }
      onSendMessage(newText, undefined, true);
    }
  };

  const onRefreshMessageText = async (messageId: string) => {
    if (loadingMessageExists || messageIsProcessing) return;
    const messageIndex = messages.findIndex((message) => message.id === messageId);
    if (messageIndex >= 0) {
      updatedMessages.current = [...messages.splice(0, messageIndex)];
      const lastUserQuestion = updatedMessages.current.slice(-1)[0];
      setMessages(updatedMessages.current);
      if (chatHistory.current?._id) {
        try {
          // delete also question because the backend store always question and answer
          await deleteMessagesInChatHistory(chatHistory.current._id, messageIndex - 1);
        } catch (error) {
          console.error('Error in deleteMessagesInChatHistory', error);
        }
      }
      onSendMessage(lastUserQuestion.text, undefined, true);
    }
  };

  const showWelcomeScreen = !messages.length;

  const isMyMessage = (message: MessageType) => message.uid === user?._id || message.uid === EXTERNAL_USER_ID;

  const getModelName = () => GPT_MODEL_SELECTION.find((m) => m.value === gptChatBoxLLM)?.label ?? '';

  const showKnowledgeMessages =
    (knowledgeBaseMode || multiDocumentMode || sidebarDocumentReviewMode) && !imageCreationModeActive;

  return (
    <Layout
      style={{
        width: '100%',
        height:
          chatGptMode || agentSearchMode
            ? `calc(100vh - ${mobileOnly ? '6rem' : '0rem'})`
            : sidebarDocumentReviewMode
              ? `calc(100vh - ${mobileOnly ? '12rem' : tabletOnly ? '10rem' : '9.5rem'})`
              : `calc(100vh - ${mobileOnly ? '12rem' : '6rem'})`,
      }}
    >
      {!knowledgeBaseMode && !sidebarDocumentReviewMode && (
        <Row
          align={'middle'}
          justify={'space-between'}
          style={{
            padding: '0.5rem',
            backgroundColor: 'var(--background-color)',
            borderBottom: '1px solid var(--border-color)',
          }}
        >
          <Col>
            <Space>
              <S.HeaderText>{getHeaderTitle()}</S.HeaderText>
              <ModeTag>
                {mobileOnly ? null : (
                  <NormalText light size="s">
                    {getModelName()}
                  </NormalText>
                )}
              </ModeTag>
            </Space>
          </Col>
          <Col>
            <Button type="text" style={{ border: 0 }} onClick={() => setShowChatbar(true)}>
              <Space size={'small'}>
                <NormalText semiBold size="s" style={{ marginBottom: '4px' }}>
                  {t('converationHistory')}
                </NormalText>
                <IconLayoutSidebarRightExpand size={'1.5rem'} />
              </Space>
            </Button>
          </Col>
        </Row>
      )}
      {knowledgeBaseMode && !refreshing && (
        <GptChatBoxFilter
          openFilterDrawer={(value: boolean) => setShowFilterDrawer(value)}
          selectedKnowledgeAreaId={selectedKnowledgeAreaId ?? ''}
        />
      )}
      {showWelcomeScreen ? (
        <Layout.Content style={S.MainChatContentStyle}>
          <EmptyGptChatBox
            agentSearchMode={agentSearchMode || internetSearchActive}
            refreshing={refreshing}
            knowledgeBaseMode={knowledgeBaseMode}
            onSendMessage={onSendMessage}
            title={
              knowledgeBaseMode
                ? t('knowledgeBase.title', { title: selectedKnowledgeArea?.title })
                : t('chatBox.emptyTitle')
            }
          />
        </Layout.Content>
      ) : (
        <Layout.Content style={S.MainChatContentStyle} ref={chatContainerRef} onScroll={handleScroll}>
          {refreshing && <LoadingSpin marginTop={'5rem'} />}
          {!refreshing && (
            <MessageWrapper>
              {messages.map((message: MessageType, index) => {
                const previousMessage = messages[index - 1];
                const showTimestamp =
                  !previousMessage ||
                  moment.unix(message.createdAt).format('LLL') !== moment.unix(previousMessage.createdAt).format('LLL');

                return (
                  <>
                    {isMyMessage(message) && (
                      <MessageDateWrapper showDate={showTimestamp}>
                        {showTimestamp && (
                          <NormalText colorType="gray" light centered size="s">
                            {moment.unix(message.createdAt).fromNow()}
                          </NormalText>
                        )}
                      </MessageDateWrapper>
                    )}
                    {showKnowledgeMessages ? (
                      <KnowledgeMessage
                        key={'message_' + index}
                        message={message}
                        isGenerating={
                          (message.text === waitText || message.isGenerating) && !message.errorOnMessageProcessing
                        }
                        errorOnMessageProcessing={message.errorOnMessageProcessing}
                        isSidebarMode={sidebarDocumentReviewMode}
                        showAllSourcesButton={!sidebarDocumentReviewMode}
                        onShowSources={(allSources: SourceDocumentType[]) =>
                          openSourceDrawer(allSources, messages[index - 1].text, message.text, message.id)
                        }
                        onInlineSourceClick={(source?: SourceDocumentType) => {
                          if (source) {
                            if (sidebarDocumentReviewMode && onInlineSourceClick) {
                              onInlineSourceClick(source);
                            } else {
                              dispatch(
                                setSourceAuditInteractionData({
                                  value: {
                                    id: 'SHOW_PDF_VIEWER',
                                    data: {
                                      source,
                                      messageId: message.id,
                                      question: messages[index - 1].text,
                                      answer: message.text,
                                    },
                                  },
                                }),
                              );
                            }
                          }
                        }}
                        allowEdit
                        onUpdateMessageText={onUpdateMessageText}
                        onRefreshMessageText={onRefreshMessageText}
                        getSourceDocumentEntry={getSourceDocumentEntry}
                      />
                    ) : (
                      <Message
                        key={'message_' + index}
                        message={message}
                        isGenerating={
                          (message.text === waitText || message.isGenerating) && !message.errorOnMessageProcessing
                        }
                        errorOnMessageProcessing={message.errorOnMessageProcessing}
                        allowEdit
                        isImageCreationMode={imageCreationModeActive}
                        onUpdateMessageText={onUpdateMessageText}
                        onRefreshMessageText={onRefreshMessageText}
                      />
                    )}
                  </>
                );
              })}
            </MessageWrapper>
          )}
          <div ref={messagesEndRef} />
        </Layout.Content>
      )}

      {!showWelcomeScreen && showScrollDownButton && <ScrollDownButton onScrollDownClick={handleScrollDown} />}
      <Layout.Footer
        style={{
          position: 'sticky',
          width: '100%',
          padding: mobileOnly ? '0.5rem' : undefined,
          paddingBottom: mobileOnly ? '0.5rem' : sidebarDocumentReviewMode ? '1rem' : '3rem',
          marginTop: -20,
          backgroundColor: 'var(--secondary-background-color)',
          background: 'var(--send-message-footer-background-color)',
        }}
      >
        {connectionAlive === false && (
          <ErrorWrapper>
            <Row gutter={10}>
              <Col>
                <InfoCircleOutlined />
              </Col>
              <Col>
                <NormalText>{t('connectionLostReloadPage')}</NormalText>
              </Col>
            </Row>
          </ErrorWrapper>
        )}
        {multiDocumentMode && (alreadyUploadedFiles.length > 0 || uploaderSelectedFiles.length > 0) && (
          <UploadedDocumentsWrapper>
            <Row style={S.HeaderFilesContentStyle} align={'middle'}>
              {alreadyUploadedFiles.map((document) => (
                <Tooltip key={document.title} title={'Selected files and source'}>
                  <DocumentTagStyled
                    closable
                    onClose={() => onDeleteUploadedFile(document.title)}
                    closeIcon={
                      <IconX
                        size={12}
                        style={{ marginLeft: '0.5rem', marginBottom: '0.2rem', verticalAlign: 'middle' }}
                      />
                    }
                  >
                    {document.title}
                  </DocumentTagStyled>
                </Tooltip>
              ))}
              {uploaderSelectedFiles.map((file) => (
                <Tooltip key={file.name} title={'Selected files and source'}>
                  <DocumentTagStyled
                    closable
                    onClose={() => onDeleteUploaderSelectedFile(file.name)}
                    closeIcon={
                      <IconX
                        size={12}
                        style={{ marginLeft: '0.5rem', marginBottom: '0.2rem', verticalAlign: 'middle' }}
                      />
                    }
                  >
                    {file.name}
                  </DocumentTagStyled>
                </Tooltip>
              ))}
            </Row>
          </UploadedDocumentsWrapper>
        )}
        <SendMessage
          onSendMessage={(message: string, uploadedFiles?: UploadFile[]) => onSendMessage(message, uploadedFiles)}
          messageIsProcessing={messageIsProcessing}
          loadingMessageExists={loadingMessageExists}
          allowFileUpload={multiDocumentMode}
          handleOnStop={handleOnStopMessageStream}
          onFileUploaded={onNewFileUploaded}
          showAIModeSwitcher={chatGptMode}
          showSettings={true}
          insideSidebar={sidebarDocumentReviewMode}
          presetQuestion={presetQuestion}
        />
      </Layout.Footer>
      <SourceAuditDrawer
        onCloseSourceDrawer={onCloseSourceDrawer}
        showSourceDrawer={showSourceDrawer}
        toShowDrawerSourceDocuments={toShowDrawerSourceDocuments}
        sourceDrawerRelatedText={sourceDrawerRelatedText}
        onGenerateAnswerWithSelectedSources={onGenerateAnswerWithSelectedSources}
      />
      <ChatHistoryDrawer
        showHistoryDrawer={showChatbar}
        onCloseHistoryDrawer={() => setShowChatbar(false)}
        chatMode={chatMode}
      />
      <FilterDrawer
        selectedKnowledgeArea={selectedKnowledgeArea}
        showFilterDrawer={showFilterDrawer}
        showValidityPeriodFields={showValidityPeriodFields}
        onCloseFilterDrawer={() => setShowFilterDrawer(false)}
      />
      <LlmSetupDrawer
        showLlmSetupDrawer={showLlmSetup}
        onCloseLlmSetupDrawer={() => setShowLlmSetup(false)}
        knowledgeBaseMode={knowledgeBaseMode}
      />
    </Layout>
  );
};

export default GptChatBox;
