import React, { useEffect, useState } from 'react';
import * as S from './DocumentContainer.styles';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Table, Modal, Input, Form, Select, AutoComplete, message, Switch, Tag } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button as CustomButton } from '../common/buttons/Button/Button';
import { ChecklistImportResult, DocumentChecklist, DocumentChecklistElement } from '@app/types/documentCheckTypes';
import { IconEdit, IconFileImport, IconTrash } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { updateDocumentChecklist } from '@app/api/documentChecklist.api';
import NavigationHeader from '../common/NavigationHeader/NavigationHeader';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { LocalStoredFile, SingleFileUploader } from '../singleFileUploader/SingleFileUploader';
import { useHandleChecklists } from '@app/hooks/useHandleChecklists';
import { extractChecklistCriterias } from '@app/api/gpt.api';
import { v4 as uuidv4 } from 'uuid';
import { SupportedFileTypesForUploader } from '@app/utils/constants';

export const EditChecklistsContainer: React.FC = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditBasicsModalVisible, setIsEditBasicsModalVisible] = useState(false);
  const [isUploadFileModalVisible, setIsUploadFileModalVisible] = useState(false);
  const [optimiseQuestions, setOptimiseQuestions] = useState(false);
  const [documentChecklist, setDocumentChecklist] = useState<DocumentChecklist | null>(null);
  const [form] = Form.useForm();
  const [basicsForm] = Form.useForm();
  const [editingItem, setEditingItem] = useState<DocumentChecklistElement | null>(null);
  const [filesToImportFrom, setFilesToImportFrom] = useState<LocalStoredFile[]>([]);
  const [importetChecklistResults, setImportetChecklistResults] = useState<ChecklistImportResult[]>([]);
  const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({});
  const [isLoadingChecklist, setIsLoadingChecklist] = useState<boolean>(false);

  const navigate = useNavigate();
  const { id: selectedChecklistId } = useParams();

  const { handleApiError } = useErrorHandling();

  const { documentChecklists, documentChecklistLoading, updateChecklistElements } = useHandleChecklists();

  const showEditBasicsModal = () => {
    basicsForm.setFieldsValue({
      name: documentChecklist?.name,
      category: documentChecklist?.category || 'OTHER_DOCUMENT',
    });
    setIsEditBasicsModalVisible(true);
  };

  const handleBasicInfosOk = () => {
    basicsForm.validateFields().then(async (values) => {
      try {
        if (selectedChecklistId) {
          await updateDocumentChecklist(selectedChecklistId, { ...documentChecklist, ...values });
          setDocumentChecklist((prev) => {
            if (!prev) return null;
            return { ...prev, ...values };
          });
        }
      } catch (error) {
        handleApiError(error);
      }
      setIsEditBasicsModalVisible(false);
      basicsForm.resetFields();
    });
  };

  const handleBasicsCancel = () => {
    setIsEditBasicsModalVisible(false);
    basicsForm.resetFields();
  };

  const handleAddOrEditOk = () => {
    form.validateFields().then(async (values) => {
      try {
        if (selectedChecklistId) {
          let updatedElements: DocumentChecklistElement[] = [];
          if (editingItem) {
            updatedElements =
              documentChecklist?.elements.map((el) => (el._id === editingItem._id ? { ...el, ...values } : el)) || [];
          } else {
            updatedElements = [
              ...(documentChecklist?.elements || []),
              { ...values, isActive: true, isOptional: false },
            ];
          }

          await updateChecklistElements(selectedChecklistId, updatedElements);
        }
      } catch (error) {
        handleApiError(error);
      }
      setEditingItem(null);
      setIsModalVisible(false);
      form.resetFields();
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleFileUpload = (file: LocalStoredFile): void => {
    setFilesToImportFrom([file]);
  };

  const handleFileRemove = (): void => {
    setFilesToImportFrom([]);
  };

  useEffect(() => {
    if (documentChecklists && selectedChecklistId) {
      setDocumentChecklist(documentChecklists?.find((item) => item._id === selectedChecklistId) || null);
    }
  }, [documentChecklists, selectedChecklistId]);

  const onDelete = (item: DocumentChecklistElement) => {
    Modal.confirm({
      title: t('documentChecker.deleteChecklistItem'),
      icon: <ExclamationCircleOutlined />,
      content: t('documentChecker.deleteChecklistItemAreYouSure'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: async () => {
        try {
          const updatedElements = documentChecklist?.elements.filter((el) => el._id !== item._id) || [];
          if (selectedChecklistId) {
            await updateChecklistElements(selectedChecklistId, updatedElements);
          }
        } catch (error) {
          handleApiError(error);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const openEditModal = (item: DocumentChecklistElement) => {
    setEditingItem(item);
    const type = item.type || 'MUST';
    form.setFieldsValue({ ...item, type });
    setIsModalVisible(true);
  };

  const openCreateModal = () => {
    const type = 'MUST';
    form.setFieldsValue({ type });
    setIsModalVisible(true);
  };

  const getCategoryText = (item: DocumentChecklist | null) => {
    if (item?.category === 'LEGAL_DOCUMENT') {
      return 'Legal';
    } else if (item?.category === 'TECHNICAL_DOCUMENT') {
      return 'Technical';
    } else {
      return 'Other';
    }
  };

  const getUniqueTitles = () => {
    const titles = new Set();
    (documentChecklist?.elements || []).forEach((item) => item.topic && titles.add(item.topic.trim()));
    return Array.from(titles).map((title) => ({ value: title }));
  };

  const toggleExpand = (key: string) => {
    setExpandedItems((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const onStartImportFromFile = async () => {
    if (isLoadingChecklist) return;
    if (filesToImportFrom.length) {
      try {
        setIsLoadingChecklist(true);
        const formData = new FormData();
        formData.append(
          'files',
          filesToImportFrom[0].originFileObj as Blob,
          encodeURIComponent(filesToImportFrom[0].name),
        );
        formData.append('optimiseQuestions', optimiseQuestions ? 'true' : 'false');
        const data = await extractChecklistCriterias(formData);
        setImportetChecklistResults(data || []);
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoadingChecklist(false);
        setFilesToImportFrom([]);
      }
    } else if (importetChecklistResults.length && selectedChecklistId) {
      const updatedElements: DocumentChecklistElement[] = documentChecklist?.elements || [];
      importetChecklistResults.forEach((item) => {
        updatedElements.push({
          _id: uuidv4(),
          topic: item.topic,
          criteria: item.questionOrCriteria,
          furtherContext: item.furtherContext,
          isActive: true,
          isOptional: false,
        });
      });
      await updateChecklistElements(selectedChecklistId, updatedElements);
      setIsUploadFileModalVisible(false);
      setImportetChecklistResults([]);
      message.success(t('documentChecker.importItemsFromDocumentSuccess', { number: updatedElements.length }));
    }
  };

  const deleteImporteEntry = (item: ChecklistImportResult) => {
    setImportetChecklistResults((prev) =>
      prev.filter(
        (prevItem) => !(prevItem.topic === item.topic && prevItem.questionOrCriteria === item.questionOrCriteria),
      ),
    );
  };

  const renderTextWithToggle = (text: string, key: string) => {
    const isExpanded = expandedItems[key];
    const displayText = isExpanded ? text : text.slice(0, 200) + (text.length > 200 ? '...' : '');
    return (
      <NormalText size="s">
        {displayText}
        {text.length > 200 && (
          <Button type="link" onClick={() => toggleExpand(key)} size="small">
            <NormalText size="s" colorType="primary">
              {isExpanded ? 'show less' : 'show more'}
            </NormalText>
          </Button>
        )}
      </NormalText>
    );
  };

  const renderQuestionType = (result: DocumentChecklistElement) => {
    let text = '';
    if (result.type === 'MUST' || (!result.type && result.isOptional === false)) {
      text = t('documentChecker.mustType');
    } else if (result.type === 'SHOULD') {
      text = t('documentChecker.shouldType');
    } else {
      text = t('documentChecker.optionalType');
    }
    return (
      <Tag
        style={{
          fontSize: '0.75rem',
          border: 0,
          color: 'var(--text-light-color)',
          backgroundColor: 'var(--secondary-background-color)',
        }}
      >
        {text}
      </Tag>
    );
  };

  return (
    <>
      <NavigationHeader title={t('documentChecker.editChecklist')} />

      <S.ChecklistContainerWrapper>
        <S.HeaderContainerWrapper>
          <Row justify={'space-between'} align={'bottom'}>
            <Col>
              <Space size={'middle'}>
                <NormalText bold size="xl" bottomMargin="s">
                  {documentChecklist?.name}
                </NormalText>
                <Button type="text" icon={<IconEdit size={18} />} onClick={showEditBasicsModal} />
              </Space>
              <NormalText bottomMargin="s">
                {t('documentChecker.checklistCategory')}: {getCategoryText(documentChecklist)}
              </NormalText>
              {documentChecklist?.description && (
                <NormalText>
                  {t('documentChecker.description')}: <br />
                  {documentChecklist.description}
                </NormalText>
              )}
            </Col>
            <Col>
              <Space>
                <Button type="dashed" onClick={() => setIsUploadFileModalVisible(true)}>
                  <Space>
                    <IconFileImport size={18} />
                    {t('documentChecker.importItemsFromDocument')}
                  </Space>
                </Button>
                <CustomButton
                  type="primary"
                  onClick={() =>
                    navigate('/contractanalyze?tab=1', { state: { presetChecklistId: selectedChecklistId } })
                  }
                >
                  {t('documentChecker.startNewReview')}
                </CustomButton>
              </Space>
            </Col>
          </Row>
        </S.HeaderContainerWrapper>
        <Table
          dataSource={documentChecklist?.elements || []}
          loading={documentChecklistLoading}
          pagination={{ hideOnSinglePage: true, defaultPageSize: 100, size: 'small' }}
          rowKey={(item) => `${item._id}`}
          childrenColumnName="_ignore_"
          columns={[
            {
              title: t('documentChecker.checklistItemName') as string,
              dataIndex: 'topic',
              key: 'topic',
              render: (_, record) => (
                <NormalText size="s" light={!record.topic}>
                  {record.topic || '-'}
                </NormalText>
              ),
              defaultSortOrder: null,
              sorter: (a, b) => {
                const aCompare = a.topic || '-';
                const bCompare = b.topic || '-';
                return aCompare.localeCompare(bCompare);
              },
            },
            {
              title: t('documentChecker.checklistItemQuestion') as string,
              dataIndex: 'criteria',
              key: 'criteria',
              render: (_, record) => renderTextWithToggle(record.criteria, `${record._id}-criteria`),
              defaultSortOrder: null,
              sorter: (a, b) => a.criteria.localeCompare(b.criteria),
            },
            {
              title: t('documentChecker.checklistItemContext') as string,
              dataIndex: 'furtherContext',
              key: 'furtherContext',
              render: (_, record) => renderTextWithToggle(record.furtherContext ?? '-', `${record._id}-context`),
              defaultSortOrder: null,
              sorter: (a, b) => {
                const aCompare = a.furtherContext || '-';
                const bCompare = b.furtherContext || '-';
                return aCompare.localeCompare(bCompare);
              },
            },
            {
              title: t('documentChecker.typeTitleShort') as string,
              dataIndex: 'type',
              key: 'type',
              width: 120,
              render: (_, record) => renderQuestionType(record),
              defaultSortOrder: null,
              sorter: (a, b) => {
                const aCompare = a.type || 'MUST';
                const bCompare = b.type || 'MUST';
                return aCompare.localeCompare(bCompare);
              },
            },
            {
              title: t('documentChecker.checklistActions') as string,
              key: 'actions',
              width: '50px',
              render: (_, item) => (
                <Space size={'small'} style={{ gap: 0 }}>
                  <Button type="text" onClick={() => openEditModal(item)}>
                    <IconEdit size={16} />
                  </Button>
                  <Button type="text" onClick={() => onDelete(item)}>
                    <IconTrash size={16} />
                  </Button>
                </Space>
              ),
            },
          ]}
        />
        <S.FooterContainerWrapper>
          <Row justify={'center'} align={'bottom'}>
            <Col>
              <Button type="primary" onClick={() => openCreateModal()}>
                {t('documentChecker.createChecklistItem')}
              </Button>
            </Col>
          </Row>
        </S.FooterContainerWrapper>
      </S.ChecklistContainerWrapper>

      <Modal
        title={editingItem ? t('documentChecker.editEntry') : t('documentChecker.newEntry')}
        open={isModalVisible}
        onOk={handleAddOrEditOk}
        onCancel={handleCancel}
      >
        <Form form={form}>
          <Form.Item
            key={'topic'}
            labelCol={{ span: 24 }}
            label={t('documentChecker.checklistItemName')}
            name={'topic'}
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <AutoComplete options={getUniqueTitles()} />
          </Form.Item>
          <Form.Item
            key={'criteria'}
            labelCol={{ span: 24 }}
            label={t('documentChecker.checklistItemQuestion')}
            name={'criteria'}
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="type"
            labelCol={{ span: 24 }}
            label={t('documentChecker.typeTitle')}
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="MUST">{t('documentChecker.mustType')}</Select.Option>
              <Select.Option value="SHOULD">{t('documentChecker.shouldType')}</Select.Option>
              <Select.Option value="OPTIONAL">{t('documentChecker.optionalType')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            key={'furtherContext'}
            labelCol={{ span: 24 }}
            label={t('documentChecker.checklistItemContext')}
            name={'furtherContext'}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Basis Informationen bearbeiten"
        open={isEditBasicsModalVisible}
        onOk={handleBasicInfosOk}
        onCancel={handleBasicsCancel}
      >
        <Form form={basicsForm}>
          <Form.Item
            key={'name'}
            labelCol={{ span: 24 }}
            label={t('Titel')}
            name={'name'}
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            key={'category'}
            labelCol={{ span: 24 }}
            label={t('Kategorie')}
            name={'category'}
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <Select>
              <Select.Option value="LEGAL_DOCUMENT">Legal document</Select.Option>
              <Select.Option value="TECHNICAL_DOCUMENT">Technical document</Select.Option>
              <Select.Option value="OTHER_DOCUMENT">Other document</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t('documentChecker.importChecklist')}
        open={isUploadFileModalVisible}
        width={800}
        onOk={() => onStartImportFromFile()}
        onCancel={() => setIsUploadFileModalVisible(false)}
        okText={t(
          importetChecklistResults.length ? t('documentChecker.importResults') : t('documentChecker.startImport'),
        )}
      >
        <SingleFileUploader
          fileData={filesToImportFrom[0]}
          onFileUploaded={handleFileUpload}
          onFileRemove={handleFileRemove}
          acceptedFileTypes={SupportedFileTypesForUploader}
          backgroundColor="var(--secondary-background-color)"
          secondDescription={t('documentChecker.importUploaderDescription')}
        />
        <S.MenuItemsContainer>
          <S.MenuItemWrapper>
            <NormalText>{t('documentChecker.optimiseQuestions')}</NormalText>
            <Switch checked={optimiseQuestions} onChange={() => setOptimiseQuestions(!optimiseQuestions)} />
          </S.MenuItemWrapper>
        </S.MenuItemsContainer>
        <Table
          dataSource={importetChecklistResults}
          loading={isLoadingChecklist}
          pagination={{ hideOnSinglePage: true, defaultPageSize: 100, size: 'small' }}
          rowKey={(item) => `${item.questionOrCriteria}`}
          expandable={{ childrenColumnName: '_ignore_' }}
          columns={[
            {
              title: t('documentChecker.checklistItemName') as string,
              dataIndex: 'title',
              key: 'title',
              render: (_, record) => (
                <NormalText size="s" light={!record.topic}>
                  {record.topic || '-'}
                </NormalText>
              ),
              defaultSortOrder: null,
              sorter: (a, b) => {
                const aCompare = a.topic || '-';
                const bCompare = b.topic || '-';
                return aCompare.localeCompare(bCompare);
              },
            },
            {
              title: t('documentChecker.checklistItemQuestion') as string,
              dataIndex: 'criteria',
              key: 'criteria',
              render: (_, record) =>
                renderTextWithToggle(record.questionOrCriteria, `${record.questionOrCriteria}-criteria`),
              defaultSortOrder: null,
              sorter: (a, b) => a.questionOrCriteria.localeCompare(b.questionOrCriteria),
            },
            {
              title: t('documentChecker.checklistItemContext') as string,
              dataIndex: 'furtherContext',
              key: 'furtherContext',
              render: (_, record) =>
                renderTextWithToggle(record.furtherContext ?? '-', `${record.furtherContext}-context`),
              defaultSortOrder: null,
              sorter: (a, b) => {
                const aCompare = a.furtherContext || '-';
                const bCompare = b.furtherContext || '-';
                return aCompare.localeCompare(bCompare);
              },
            },
            {
              title: t('documentChecker.checklistActions') as string,
              key: 'actions',
              width: '50px',
              render: (_, item) => (
                <Button type="text" icon={<IconTrash size={18} />} onClick={() => deleteImporteEntry(item)} />
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
};
