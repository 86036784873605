import { shadeColor } from '@app/utils/utils';
import { graphic } from 'echarts';
import { BASE_COLORS } from '../constants';
import { ITheme } from '../types';

const chartColors = {
  chartTooltipLabel: '#6A7985',
  chartColor1: '#01509A',
  chartColor1Tint: '#2983D8',
  chartColor2: '#35A0DC',
  chartColor2Tint: '#67C5FA',
  chartColor3: '#FFB155',
  chartColor3Tint: '#FFA800',
  chartColor4: '#31A652',
  chartColor4Tint: '#89DCA0',
  chartColor5: '#FF5252',
  chartColor5Tint: '#FFC1C1',
  chartPrimaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(0, 110, 211, 0.5)',
    },
    {
      offset: 1,
      color: 'rgba(255, 225, 255, 0)',
    },
  ]),
  chartSecondaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 82, 82, 0.5)',
    },
    {
      offset: 1,
      color: 'rgba(255, 255, 255, 0)',
    },
  ]),
  chartSecondaryGradientSpecular: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 255, 255, 0)',
    },
    {
      offset: 1,
      color: 'rgba(255, 82, 82, 0.5)',
    },
  ]),
};

export const main_color = '#0179D0'; //'#0069E5'; //'#00024e'; '#008bbd'; '#2D8EE9' //#007BFF
//export const main_color = '#1C65F1';

export const lightColorsTheme: ITheme = {
  primary: main_color,
  primary1: '#f5f5f5',
  primaryLight: '#CBE6FA',
  primaryGradient: 'linear-gradient(211.49deg, #590AA8 15.89%, #2D8EE9 70.97%)',
  primaryGradientHover: 'linear-gradient(211.49deg, #8D45D4 15.89%, #2D8EE9 50.97%)',
  light: '#C5D3E0',
  secondary: main_color,
  error: '#FF5252',
  warning: '#FFB155',
  success: '#30AF5B',
  background: BASE_COLORS.white,
  secondaryBackground: '#f9f9f9',
  secondaryBackgroundSelected: '#F6F6F6',
  additionalBackground: BASE_COLORS.white,
  collapseBackground: main_color,
  backgroundColorBase: '#F5F5F5',
  actionsBackgroundColorBase: '#f9f9f9',
  tableHeaderBackground: '#d7d7d7',
  skeletonBackground: '#E7E7E7',
  timelineBackground: '#f9f9f9',
  errorBackground: 'rgb(254, 195, 195)',
  siderBackground: BASE_COLORS.white,
  spinnerBase: main_color,
  messageBubleBackground: 'rgb(253,253,253, 0.9)',
  sendMessageFooterBackground:
    'linear-gradient(180deg, rgba(249,249,249,0.2) 0, rgba(249,249,249,0.6) 5%, rgba(249,249,249,1) 12%, rgba(249,249,249,1) 100%)',
  secondaryButtonBackground: '#fff',
  secondaryButtonActiveBackground: 'rgb(214, 236, 250)',
  dragableItemBackground: 'aliceblue',
  tooltipBackground: '#3f3f3f',
  scroll: '#c5d3e0',
  border: '#cce1f4',
  borderDragAndDrop: '#6FC1FF',
  borderNft: '#79819A',
  textMain: '#2D2D2D',
  textLight: '#9A9B9F',
  textSuperLight: '#BEC0C6',
  textSecondary: BASE_COLORS.white,
  textDark: '#404040',
  textNftLight: '#79819A',
  textWarning: '#F49931',
  textSiderPrimary: BASE_COLORS.white,
  textSiderSecondary: '#475569',
  subText: 'rgba(0, 0, 0, 0.45)',
  shadow: 'rgba(0, 0, 0, 0.07)',
  boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.07)',
  boxShadowHover: '0 4px 16px 0 rgba(0, 0, 0, 0.2)',
  notificationSuccess: '#EFFFF4',
  notificationPrimary: '#D7EBFF',
  notificationWarning: '#FFF4E7',
  notificationError: '#FFE2E2',
  heading: '#444444;',
  borderBase: '#dadde5',
  borderSidebar: '#cce1f4',
  disable: 'rgba(0, 0, 0, 0.25)',
  disabledBg: '#c5d3e0',
  layoutBodyBg: '#f8fbff',
  layoutSiderBg: BASE_COLORS.white,
  layoutSiderContentBg: BASE_COLORS.white,
  layoutHeaderBg: BASE_COLORS.white,
  inputPlaceholder: '#404040',
  itemHoverBg: '#f5f5f5',
  avatarBg: '#F49931',
  alertTextColor: BASE_COLORS.white,
  breadcrumb: 'rgba(0, 0, 0, 0.45)',
  icon: '#a9a9a9',
  iconHover: 'rgba(0, 0, 0, 0.75)',
  chatEditInputBackground: '#F7F9FC',
  warningBackground: '#fcf4e4',
  ...chartColors,
};
