import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doActivate2FA } from '@app/store/slices/authSlice';
import { generate2faCode } from '@app/api/auth.api';
import { Image, Input, message, Modal, Row, Space, Tooltip } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { Icon2fa, IconInfoCircle } from '@tabler/icons-react';
import { setUser } from '@app/store/slices/userSlice';
import { getErrorText } from '@app/utils/apiHelpers';
import undraw_two_factor_authentication from '@app/assets/images/undraw_two_factor_authentication.svg';

interface ModalEnable2FAProps {
  visible: boolean;
  showIntroScreen: boolean;
  onClose: () => void;
}

export const ModalEnable2FA: React.FC<ModalEnable2FAProps> = ({ visible, showIntroScreen, onClose }) => {
  const user = useAppSelector((state) => state.user.user);

  const [introScreenVisible, setIntroScreenVisible] = useState(showIntroScreen);
  const [check2faCode, setCheck2faCode] = useState(false);
  const [step2faModal, setStep2faModal] = useState(0);
  const [code2fa, setCode2fa] = useState('');
  const [qrCodeBlob, setQrCodeBlob] = useState<Blob | null>(null);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (visible) {
      const gen2faCode = async () => {
        const pngData: Blob = await generate2faCode(user?._id ?? '');
        setQrCodeBlob(pngData);
      };
      gen2faCode();
      setStep2faModal(0);
      setCode2fa('');
      setIntroScreenVisible(showIntroScreen);
    }
  }, [visible]);

  const on2faModalNextStep = async () => {
    if (introScreenVisible) {
      setIntroScreenVisible(false);
      return;
    }
    if (step2faModal === 0) setStep2faModal(1);
    if (step2faModal === 1) {
      if (code2fa) {
        setCheck2faCode(true);
        try {
          dispatch(
            doActivate2FA({
              userId: user?._id ?? '',
              twoFactorAuthenticationCode: code2fa,
            }),
          )
            .unwrap()
            .then((activated) => {
              if (activated) {
                dispatch(setUser({ ...user, isTwoFactorAuthenticationEnabled: true }));
                message.success('2FA was activated');
                onClose();
              } else {
                message.warn('The 2FA code is not valid');
              }
            })
            .catch((err) => {
              message.warn(err.message);
            });
        } catch (error) {
          message.error(getErrorText(error));
        } finally {
          setCheck2faCode(false);
        }
      }
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      onOk={on2faModalNextStep}
      confirmLoading={check2faCode}
      okText={step2faModal === 0 ? t('next') : t('common.confirm')}
    >
      {introScreenVisible && (
        <Row align={'middle'} justify={'center'}>
          <Space direction="vertical" style={{ marginTop: '2rem', marginBottom: '1rem', padding: '0 1rem' }}>
            <NormalText centered size="l" colorType="primary" semiBold>
              {t('profile.nav.securitySettings.2FASettingsIntroStep')}
            </NormalText>
          </Space>
          <Image src={undraw_two_factor_authentication} preview={false} style={{ width: 250, height: 250 }} />
        </Row>
      )}
      {!introScreenVisible && step2faModal === 0 && (
        <Row align={'middle'} justify={'center'}>
          <Space style={{ marginTop: '2rem', marginBottom: '1rem' }}>
            <NormalText size="m">{t('profile.nav.securitySettings.2FASettingsFirstStep')}</NormalText>
            <Tooltip title={t('profile.nav.securitySettings.2FAappsToolTip')}>
              <IconInfoCircle size={'1rem'} color="gray" style={{ marginTop: '8px' }} />
            </Tooltip>
          </Space>
          {qrCodeBlob && <img src={URL.createObjectURL(qrCodeBlob)} alt="2FA Code" />}
        </Row>
      )}
      {!introScreenVisible && step2faModal === 1 && (
        <>
          <NormalText size="m" style={{ marginBottom: '1rem' }}>
            {t('profile.nav.securitySettings.2FASettingsSecondStep')}
          </NormalText>
          <Input
            value={code2fa}
            onChange={(event) => setCode2fa(event.target.value)}
            prefix={<Icon2fa color="#9A9B9F" />}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                on2faModalNextStep();
              }
            }}
          />
        </>
      )}
    </Modal>
  );
};
