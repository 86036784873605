import React from 'react';
import { Divider, Row, Space } from 'antd';
import { ExternalLinkWrapper } from './GptChatBox.styles';
import { SourceDocumentType, SourceDocumentsSorted, isTypeDocumentOrWebsite } from '@app/types/chatBoxTypes';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { IconExternalLink } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { MemoizedReactMarkdown } from '../common/Markdown/MemoizedReactMarkdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeMathjax from 'rehype-mathjax';
import { sanitizeString } from '@app/utils/stringHelpers';

export interface PropTypes {
  index: number;
  docItem: SourceDocumentType;
  item: SourceDocumentsSorted;
  onPreviewFile: (docItem: SourceDocumentType, index: number) => void;
}

const SourceEntryItem: React.FC<PropTypes> = ({ index, docItem, item, onPreviewFile }) => {
  const { t } = useTranslation();

  const isDocumentOrWebsite = isTypeDocumentOrWebsite(docItem.type);

  if (isDocumentOrWebsite) {
    return (
      <div>
        <Row justify={'space-between'} align={'middle'}>
          <Space>
            <NormalText colorType="light" size="s">
              {t('textSnippet', { number: index + 1 })}
            </NormalText>
            {!!docItem.page && docItem.page > 0 && (
              <NormalText colorType="light" size="s">
                | {t('pageNumber', { page: docItem.page })}
              </NormalText>
            )}
          </Space>
          <ExternalLinkWrapper>
            <a onClick={() => onPreviewFile(docItem, index)}>
              <IconExternalLink style={{ width: '1.25rem', height: '1.25rem' }} />
            </a>
          </ExternalLinkWrapper>
        </Row>

        <MemoizedReactMarkdown
          className="prose flex-1"
          remarkPlugins={[remarkGfm, remarkMath]}
          rehypePlugins={[rehypeMathjax]}
          components={{
            p({ children }) {
              return <NormalText size="s">{children}</NormalText>;
            },
            table({ children }) {
              return <NormalText size="s">{children}</NormalText>;
            },
            th({ children }) {
              return <NormalText size="s">{children}</NormalText>;
            },
            td({ children }) {
              return <NormalText size="s">{children}</NormalText>;
            },
            ul({ children }) {
              return <NormalText size="s">{children}</NormalText>;
            },
            ol({ children }) {
              return <NormalText size="s">{children}</NormalText>;
            },
            li({ children }) {
              return <NormalText size="s">{children}</NormalText>;
            },
            strong({ children }) {
              return (
                <NormalText size="s" colorType="primary">
                  {children}
                </NormalText>
              );
            },
            b({ children }) {
              return (
                <NormalText size="s" colorType="primary">
                  {children}
                </NormalText>
              );
            },
          }}
        >
          {`${sanitizeString(docItem.highlightedPageContent || docItem.pageContent)}`}
        </MemoizedReactMarkdown>

        {index !== item.documents.length - 1 && <Divider />}
      </div>
    );
  } else {
    return (
      <div>
        <pre>
          <NormalText size="s">{docItem.highlightedPageContent}</NormalText>
        </pre>
      </div>
    );
  }
};

export default SourceEntryItem;
