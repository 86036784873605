import { UserModel } from '@app/domain/UserModel';
import { httpApi } from '@app/api/http.api';
import { readUser } from '@app/services/reduxStorage.service';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName?: string;
  invitationId?: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPw: string;
  code: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginRequestWith2FA {
  userId: string;
  twoFactorAuthenticationCode: string;
}

export interface LoginResponse {
  data: {
    token: string;
    securityToken: string;
    redirectTo2FA: boolean;
    user: UserModel;
  };
}

export const verifyAuth = (): Promise<UserModel> => {
  return new Promise(async (resolve, reject) => {
    const user = readUser();
    if (user) {
      try {
        const result = await httpApi.get('/api/v1/user/read/' + user._id);
        if (result.data?.data) resolve(result.data?.data);
        reject(result.data?.error ?? 'unknown error');
        return;
      } catch (error: unknown) {
        if (error instanceof Error) {
          reject(error.message);
        } else {
          reject(error);
        }
      }
    }
    reject('auth error');
  });
};

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/user/auth/signIn', {
        email: loginPayload.email,
        password: loginPayload.password,
      });
      if (result.data?.data) return resolve(result.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('Login failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const logout = (): Promise<void> => {
  //NOP
  return Promise.resolve();
};

export const signUp = (signUpData: SignUpRequest): Promise<LoginResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/user/auth/signUp', {
        companyName: signUpData.companyName,
        firstName: signUpData.firstName,
        lastName: signUpData.lastName,
        email: signUpData.email,
        password: signUpData.password,
        invitationId: signUpData.invitationId,
      });
      if (result.data?.data) return resolve(result.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('SignUp failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const resetPassword = async (email?: string): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/user/auth/sendPwResetMail', {
        email: email,
      });
      if (result.data?.data) return resolve();
      if (result.data?.error) return reject(result.data?.error);
      reject('Reset password failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/user/auth/resetPw', {
        code: newPasswordData.code,
        newPw: newPasswordData.newPw,
      });
      if (result.data?.data) return resolve();
      if (result.data?.error) return reject(result.data?.error);
      reject('Reset password failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const generate2faCode = (userId: string): Promise<Blob> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/user/auth/2fa/generate', { userId }, { responseType: 'blob' });
      if (result.data) return resolve(result.data);
      else return reject('Generate 2fa code failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const activate2faCode = (userId: string, twoFactorAuthenticationCode: string): Promise<LoginResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/user/auth/2fa/activate', { userId, twoFactorAuthenticationCode });
      if (result.data?.data) return resolve(result.data);
      if (result.data?.error) return reject(result.data?.error);
      return reject('Activate 2FA failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const authenticateWith2faCode = (
  userId: string,
  twoFactorAuthenticationCode: string,
): Promise<LoginResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/user/auth/2fa/authentication', {
        userId,
        twoFactorAuthenticationCode,
      });
      if (result.data?.data) return resolve(result.data);
      if (result.data?.error) return reject(result.data?.error);
      return reject('Authentication with 2FA-Code failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const deactivate2fa = (userId: string, twoFactorAuthenticationCode: string): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/user/auth/2fa/deactivate', { userId, twoFactorAuthenticationCode });
      if (result.data.data) return resolve(result.data.data);
      else return reject('Generate 2fa code failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

// TODO:
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): void => {
  // at the moment this is done by firebase default UI
};
