import { shadeColor } from '@app/utils/utils';
import { graphic } from 'echarts';
import { BASE_COLORS } from '../constants';
import { ITheme } from '../types';

const chartColors = {
  chartTooltipLabel: '#6a7985',
  chartColor1: '#339CFD',
  chartColor1Tint: '#339CFD', // update
  chartColor2: '#dc88f5',
  chartColor2Tint: '#dc88f5', // update
  chartColor3: '#FFB765',
  chartColor3Tint: '#FFB765', // update
  chartColor4: '#306955',
  chartColor4Tint: '#306955', // update
  chartColor5: '#ff3d71',
  chartColor5Tint: '#ff3d71', // update
  chartPrimaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(51, 156, 253, 0.35)',
    },
    {
      offset: 1,
      color: 'rgba(51, 156, 253, 0)',
    },
  ]),
  chartSecondaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 82, 82, 0.35)',
    },
    {
      offset: 1,
      color: 'rgba(255, 82, 82, 0)',
    },
  ]),
  chartSecondaryGradientSpecular: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 255, 255, 0)',
    },
    {
      offset: 1,
      color: 'rgba(255, 82, 82, 0.5)',
    },
  ]),
};

// generated by https://colorffy.com/dark-theme-generator
export const main_color = '#0179d0';
export const main_color_a10 = '#4187d6';
export const main_color_a20 = '#6195db'; // TODO: check also this: #7368EE
export const main_color_a30 = '#7ba3e0';
export const main_color_a40 = '#93b2e6';
export const main_color_a50 = '#a9c1eb';

export const surface_color_a0 = '#121212';
export const surface_color_a10 = '#282828';
export const surface_color_a20 = '#3f3f3f';
export const surface_color_a30 = '#575757';
export const surface_color_a40 = '#717171';
export const surface_color_a50 = '#8b8b8b';

export const tanal_surface_color_a0 = '#181b22'; //'#181b22'; // #141721
export const tanal_surface_color_a10 = '#2d3037'; //'#2d3037'; // ##1F222C
export const tanal_surface_color_a20 = '#44464d';
export const tanal_surface_color_a30 = '#5c5e64';
export const tanal_surface_color_a40 = '#75777c';
export const tanal_surface_color_a50 = '#8f9094';

export const darkColorsTheme: ITheme = {
  primary: main_color_a20,
  primary1: '#7568f6',
  primaryLight: '#dcf0fd',
  primaryGradient: 'linear-gradient(211.49deg, #dc88f5 15.89%, #339CFD 75.97%)',
  primaryGradientHover: 'linear-gradient(211.49deg, #dc88f5 15.89%, #339CFD 50.97%)',
  light: '#696969',
  secondary: '#0072DD',
  error: '#FF5252',
  warning: '#FFB765',
  success: '#35a35a',
  background: tanal_surface_color_a10,
  secondaryBackground: tanal_surface_color_a0,
  secondaryBackgroundSelected: shadeColor('#282828', -5),
  messageBubleBackground: tanal_surface_color_a10,
  backgroundColorBase: '#3C3D44',
  actionsBackgroundColorBase: tanal_surface_color_a20,
  tableHeaderBackground: tanal_surface_color_a20,
  //sendMessageFooterBackground: tanal_surface_color_a0,
  sendMessageFooterBackground:
    'linear-gradient(180deg, rgba(24,27,34,0.2) 0, rgba(24,27,34,0.6) 3%, rgba(24,27,34,1) 10%, rgba(24,27,34,1) 100%)',
  secondaryButtonBackground: '#434455',
  secondaryButtonActiveBackground: '#708ea9',
  dragableItemBackground: tanal_surface_color_a20,
  additionalBackground: tanal_surface_color_a0,
  skeletonBackground: tanal_surface_color_a20,
  tooltipBackground: surface_color_a0,
  collapseBackground: '#1D203E',
  timelineBackground: '#f5f5f5',
  siderBackground: tanal_surface_color_a10,
  errorBackground: 'rgb(254, 195, 195)',
  spinnerBase: main_color_a20,
  scroll: '#797C9A',
  border: tanal_surface_color_a50,
  borderDragAndDrop: '#94CFFB',
  borderNft: '#797C9A',
  textMain: '#DBE4E9',
  textLight: '#9A9B9F',
  textSuperLight: '#444',
  textSecondary: '#B8C4CD',
  textDark: '#404040',
  textNftLight: '#797C9A',
  textWarning: '#1c2137',
  textSiderPrimary: main_color_a20,
  textSiderSecondary: '#DBE4E9',
  subText: '#a9a9a9',
  shadow: 'rgba(0, 0, 0, 0.07)',
  boxShadow: 'none',
  boxShadowHover: '0px 5px 9px 0px rgba(255,255,255,0.15)',
  notificationSuccess: '#EFFFF4',
  notificationPrimary: '#D7EBFF',
  notificationWarning: '#FFF4E7',
  notificationError: '#FFE2E2',
  heading: BASE_COLORS.white,
  borderBase: tanal_surface_color_a50,
  borderSidebar: 'transparent',
  disable: '#7e7e7e',
  disabledBg: '#1c2137',
  layoutBodyBg: '#1F1F1D',
  layoutHeaderBg: '#1F1F1D',
  layoutSiderBg: tanal_surface_color_a10,
  layoutSiderContentBg: tanal_surface_color_a10,
  inputPlaceholder: 'rgba(255, 255, 255, 0.5)',
  itemHoverBg: '#1c2137',
  avatarBg: '#1c2137',
  alertTextColor: '#000',
  breadcrumb: '#a9a9a9',
  icon: '#a9a9a9',
  iconHover: '#ffffff',
  chatEditInputBackground: tanal_surface_color_a30,
  warningBackground: tanal_surface_color_a30,
  ...chartColors,
};

export const antDarkColorsTheme = {
  successBg: '#e6fff2',
  successBorder: '#79fcc4',
};
