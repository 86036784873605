import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doActivate2FA, doResetPassword } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { deactivate2fa } from '@app/api/auth.api';
import { Button, Divider, Dropdown, Input, Menu, message, Modal, Space } from 'antd';
import { ModalEnable2FA } from '@app/components/common/ModalEnable2FA';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { Icon2fa } from '@tabler/icons-react';
import { setUser } from '@app/store/slices/userSlice';
import { MoreOutlined } from '@ant-design/icons';
import { getErrorText } from '@app/utils/apiHelpers';

export const PasswordForm: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);

  const [isLoading, setLoading] = useState(false);
  const [show2faActivateModal, setShow2faActivateModal] = useState(false);
  const [show2faDeactivateModal, setShow2faDeactivateModal] = useState(false);
  const [code2fa, setCode2fa] = useState('');
  const [check2faCode, setCheck2faCode] = useState(false);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const onSendPwResetMail = () => {
    setLoading(true);
    dispatch(doResetPassword(user?.email ?? ''))
      .unwrap()
      .then(() => notificationController.success({ message: t('common.pwResetEmailSent') }))
      .finally(() => setLoading(false));
  };

  const on2faModalDeactivate = async () => {
    if (code2fa) {
      setCheck2faCode(true);
      try {
        const deactivated = await deactivate2fa(user?._id ?? '', code2fa);
        if (deactivated) {
          dispatch(setUser({ ...user, isTwoFactorAuthenticationEnabled: false }));
          message.success('2FA was deactivated');
          setShow2faDeactivateModal(false);
        } else {
          message.warn('The 2FA code is not valid');
        }
      } catch (error: any) {
        message.error(getErrorText(error));
      } finally {
        setCheck2faCode(false);
      }
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="2famenu" onClick={() => setShow2faDeactivateModal(true)}>
        {t('profile.nav.securitySettings.2FASettingsDeactivate')}
      </Menu.Item>
    </Menu>
  );

  return user?.email ? (
    <>
      <BaseButtonsForm.Title>{t('profile.nav.securitySettings.2FASettings')}</BaseButtonsForm.Title>
      {user.isTwoFactorAuthenticationEnabled ? (
        <>
          <Space style={{ marginTop: '8px' }}>
            <NormalText size="m" semiBold style={{ marginBottom: '1rem', color: 'green' }}>
              {t('profile.nav.securitySettings.2FASettingsIsEnabled')}
            </NormalText>
          </Space>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="text" icon={<MoreOutlined />} style={{ marginLeft: 4 }}></Button>
          </Dropdown>
        </>
      ) : (
        <Button
          loading={isLoading}
          htmlType="submit"
          onClick={() => setShow2faActivateModal(true)}
          style={{ marginTop: '1rem' }}
        >
          {t('profile.nav.securitySettings.2FASettingsEnable')}
        </Button>
      )}
      <Divider />
      <BaseButtonsForm.Title>{t('profile.nav.securitySettings.changePassword')}</BaseButtonsForm.Title>
      <Button loading={isLoading} htmlType="submit" onClick={onSendPwResetMail} style={{ marginTop: '1rem' }}>
        {t('profile.nav.securitySettings.sendPasswortResetEmail')}
      </Button>

      <ModalEnable2FA
        showIntroScreen={true}
        visible={show2faActivateModal}
        onClose={() => setShow2faActivateModal(false)}
      />

      <Modal
        title={t('profile.nav.securitySettings.2FASettings')}
        open={show2faDeactivateModal}
        onCancel={() => setShow2faDeactivateModal(false)}
        onOk={on2faModalDeactivate}
        confirmLoading={check2faCode}
      >
        <NormalText size="m" style={{ marginBottom: '1rem' }}>
          {t('profile.nav.securitySettings.2FASettingsHeaderDeactivate')}
        </NormalText>
        <Input
          value={code2fa}
          onChange={(event) => setCode2fa(event.target.value)}
          prefix={<Icon2fa color="#9A9B9F" />}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              on2faModalDeactivate();
            }
          }}
        />
      </Modal>
    </>
  ) : (
    <p>Loading Error</p>
  );
};
