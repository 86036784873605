import React, { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { message } from 'antd';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { useTranslation } from 'react-i18next';
import { StyledDragger } from './FileUploader.styles';
import { SupportedFileTypesForUploader } from '@app/utils/constants';

interface FileUploaderProps {
  onFileUploaded?: (file: UploadFile) => void;
  onProcessUploadedFile?: (file: UploadFile, fileUrl: string) => void;
  showUploadMessage?: boolean;
  disabled?: boolean;
  multipleFiles?: boolean;
  showUploadList?: boolean;
  description?: string;
  presetAllowedTypes?: string[];
}

const MaxFileSize = 250 * 1024 * 1024;

export const FileUploader: React.FC<FileUploaderProps> = ({
  onFileUploaded,
  onProcessUploadedFile,
  showUploadMessage,
  disabled,
  description,
  multipleFiles,
  showUploadList,
  presetAllowedTypes,
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<UploadFile | null>(null);
  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    if (file && fileUrl) {
      const fileUrlToSet = fileUrl;
      setFileUrl('');
      onProcessUploadedFile?.(file, fileUrlToSet);
    }
  }, [file, fileUrl, onProcessUploadedFile]);

  // Custom file upload method
  const customRequest = (options: any) => {
    const { onSuccess } = options;
    setFileUrl('-');
    onSuccess('done');
  };

  const validateFileType = ({ type }: UploadFile) => {
    const allowedTypes = presetAllowedTypes || SupportedFileTypesForUploader.split(', ');
    if (type) {
      return allowedTypes.some((allowed) => type.includes(allowed));
    }
  };

  const props: UploadProps = {
    name: 'file',
    multiple: multipleFiles === false ? false : true,
    showUploadList: showUploadList,
    disabled: disabled,
    customRequest,
    beforeUpload: (file: UploadFile) => {
      const isAllowedType = validateFileType(file);
      if (!isAllowedType) {
        message.error(t('invalidFileType', { fileName: file.name }));
        return false;
      }
      if (file.size) {
        const isLessThanMaxSize = file.size < MaxFileSize;
        if (!isLessThanMaxSize) {
          message.error(`Die Datei ist zu groß. Die maximale Größe beträgt ${MaxFileSize / 1024 / 1024}MB.`);
        }
        return isLessThanMaxSize; // Wenn false zurückgegeben wird, wird die Datei nicht hochgeladen.
      }
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        showUploadMessage && message.success(`${info.file.name} Datei erfolgreich hochgeladen`);
        setFile(info.file);
        onFileUploaded?.(info.file);
      } else if (status === 'error') {
        message.error(t('fileUploadError', { fileName: info.file.name }));
      }
    },
    onDrop(e) {
      //console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <StyledDragger {...props} showUploadList={false}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <NormalText size="l" verticalPadding>
        {t('clickOrDragAndDrop')}
      </NormalText>
      <NormalText size="m" colorType="light">
        {description || t('supportForSingleAndBulkUploads')}
      </NormalText>
    </StyledDragger>
  );
};
